import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import EssJobSummaryTable from './EssJobSummaryTable';

const useStyles = makeStyles(theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: '95%',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  }
}));

function EssJobShowPage(props) {
  const classes = useStyles();
  const { essJobName } = props.match.params;

  return (
    <main className={classes.main}>
      <Typography component="h1" variant="h5">ESS Job Name: {essJobName}</Typography>
      <EssJobSummaryTable essJobName={essJobName} />
    </main>
  );
}

export default EssJobShowPage;
