import React from 'react';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import Routes from '../../util/routes';


const HomeLink = props => {
  return (
    <List>
      <ListItem button component={Link} to={Routes.home} >
        <ListItemIcon><HomeIcon /></ListItemIcon>
        <ListItemText primary={'Home'} />
      </ListItem>
    </List>
  );
}

export default HomeLink;
