import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MaterialTable, { MTableToolbar } from 'material-table';
import List from '@material-ui/icons/List';
import { makeStyles } from '@material-ui/styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import DialogWrapper from '../../dialogs/DialogWrapper';
import Edi810Lines from './Edi810LinesReport';
import axios from '../../../services/axios';
import icons from '../../../util/icons';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
}));

const Edi810HeadersReport = props => {
  const classes = useStyles();
  const { token } = props;

  let ediStartDate;
  let ediEndDate;

  if (localStorage.getItem('ediEndDate')) {
    ediEndDate = localStorage.getItem('ediEndDate');
  } else {
    ediEndDate = moment().endOf('day').toISOString();
    localStorage.setItem('ediEndDate', ediEndDate);
  }

  if (localStorage.getItem('ediStartDate')) {
    ediStartDate = localStorage.getItem('ediStartDate');
  } else {
    ediStartDate = moment().subtract(7, 'days').startOf('day').toISOString();
    localStorage.setItem('ediStartDate', ediStartDate);
  }

  const dateToday = new Date(ediEndDate);
  const dateOneWeekAgo = new Date(ediStartDate);

  const [ fromDate, setFromDate ] = useState(dateOneWeekAgo);
  const [ toDate, setToDate ] = useState(dateToday);

  const columns = [
    {
      tooltip: 'View Lines',
      render: rowData => {
        return (
          <DialogWrapper
            button={({ handleClickOpen }) => {
              return (
                <IconButton size="small" onClick={handleClickOpen}>
                    <List />
                </IconButton>
              );
            }}
            dialogTitle={`Customer PO # ${rowData.customer_po_number}`}
            content={<Edi810Lines jtTransactionId={rowData.jt_transaction_id} invoiceNumber={rowData.invoice_number} />}
            maxWidth="xl"
            />
        );
      },
    },
    {
      title: "Process Message",
      field: "process_message",
      render: rowData => {
        if (rowData.process_message !== null && rowData.process_message.length > 0) {
          return (
            <DialogWrapper
              button={({ handleClickOpen }) => {
                return (
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handleClickOpen}
                  >
                      View
                    </Button>
                );
              }}
              dialogTitle='Process Message'
              content={rowData.process_message}
            />
          );
        } else {
          return null;
        }
      },
    },
    { title: "JT Transaction Id", field: "jt_transaction_id", cellStyle: { whiteSpace: "nowrap", size: "small" } },
    { title: "Process Status", field: "process_status" },
    { title: "Customer Name", field: "customer_name", cellStyle: { whiteSpace: "nowrap" } },
    { title: "Customer PO #", field: "customer_po_number", cellStyle: { whiteSpace: "nowrap" } },
    { title: "Order #", field: "order_number", cellStyle: { whiteSpace: "nowrap" } },
    { title: "Invoice #", field: "invoice_number", cellStyle: { whiteSpace: "nowrap" } },
    {
      title: "Trx Date",
      field: "trx_date",
      render: rowData => {
        if (rowData.trx_date !== null) {
          return moment(rowData.trx_date).format('l');
        }
      },
    },
    { title: "Shipment #", field: "shipment_number" },
    { title: "Edi Location Code", field: "edi_location_code" },
    { title: "# of Lines", field: "num_lines" },
    { title: "Line Total", field: "line_total" },
    {
      title: "Creation Date",
      field: "creation_date",
      defaultSort: "desc",
      render: rowData => {
        if (rowData.creation_date !== null) {
          return moment(rowData.creation_date).format('MM/DD/YYYY h:mm:ss a');
        }
      }
    },
  ];

  const [headerData, setHeaderData] = useState([]);

  useEffect(() => {
    const fetch810Headers = async () => {
      const endpoint = '/reports/810-headers';
      const bearerToken = `Bearer ${token}`;
      const options = {
        headers: {
          Authorization: bearerToken
        },
        params: {
          fromDate,
          toDate,
        },
      };

      let transactionData = [];
      try {
        const response = await axios.get(endpoint, options);
        transactionData = response.data.transactions;
        setHeaderData(transactionData);
      } catch (error) {
        transactionData = [];
        setHeaderData(transactionData);
      }
    };

    fetch810Headers();
  }, [token, fromDate, toDate]);

  const renderDateFilter = props => {
    return (
      <>
        <Grid container justify="flex-end">
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="from-date-picker-dialog"
                label="From date"
                format="MM/dd/yyyy"
                value={fromDate}
                onChange={date => {
                  localStorage.setItem('ediStartDate', date.toISOString());
                  setFromDate(date);
                }}
              />
              <KeyboardDatePicker
                margin="normal"
                id="to-date-picker-dialog"
                label="To date"
                format="MM/dd/yyyy"
                value={toDate}
                onChange={date => {
                  localStorage.setItem('ediEndDate', date.toISOString());
                  setToDate(date);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change to date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item>
            <MTableToolbar {...props}/>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <main className={classes.main}>
      <MaterialTable
        columns={columns}
        data={headerData}
        title="EDI 810 Transactions"
        options={{
          filtering: true,
          grouping: false,
          search: false,
          columnsButton: true,
          exportButton: true,
          exportAllData: true,
          padding: 'dense',
          pageSize: 25,
          pageSizeOptions: [25, 50, 100],
          emptyRowsWhenPaging: false,
          showTitle: false,
          maxBodyHeight: "10%",
        }}
        icons={icons}
        components={{
          Toolbar: renderDateFilter
        }}
      />
    </main>
  );
};

const mapStateToProps = ({ token }) => {
  return { token };
}

export default connect(mapStateToProps)(Edi810HeadersReport);
