import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import moment from 'moment';
import axios from '../../../services/axios';
import icons from '../../../util/icons';


const Edi940Lines = props => {
  const { jtTransactionId, token } = props;
  const [lineData, setLineData] = useState([]);

  const columns = [
    { title: "Item", field: 'item' },
    { title: "Oracle Requested Qty", field: "requested_quantity" },
    { title: "Oracle Shipment Line Id", field: 'oracle_shipment_line_id' },
    { title: "Oracle Shipment", field: 'oracle_shipment' },
    { title: "Oracle Shipment", field: 'uom' },
    { title: "Freight Class", field: 'freight_class' },
    { title: "Commodity Code", field: 'commodity_code' },
    { title: "Unit Selling Price", field: 'unit_selling_price' },
    { title: "Process Status", field: "process_status" },
    { title: "Process Message", field: "process_message" },
    {
      title: "Creation Date",
      field: "creation_date",
      defaultSort: "desc",
      render: rowData => {
        if (rowData.creation_date !== null) {
          return moment(rowData.creation_date).format('MM/DD/YYYY h:mm:ss a');
        }
      }
    },
  ];


  useEffect(() => {
    const fetch850Headers = async () => {
      const endpoint = `/reports/940-lines/${jtTransactionId}`;
      const bearerToken = `Bearer ${token}`;
      const options = {
        headers: {
          Authorization: bearerToken
        }
      };

      let lines = [];
      try {
        const response = await axios.get(endpoint, options);
        lines = response.data.lines;
        setLineData(lines);
      } catch (error) {
        lines = [];
        setLineData(lines);
      }
    };

    fetch850Headers();
  }, [jtTransactionId, token]);

  return (
    <MaterialTable
      columns={columns}
      data={lineData}
      doubleHorizontalScroll={true}
      icons={icons}
      options={{
        doubleHorizontalScroll: true,
        filtering: true,
        paging: false,
        search: false,
        showTitle: false,
        sorting: false,
        title: false,
        toolbar: false,
      }}
    />
  );
};

const mapStateToProps = (state) => {
  const { token } = state;

  return {
    token
  };
}

export default connect(mapStateToProps)(Edi940Lines);
