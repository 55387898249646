import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import MailIcon from '@material-ui/icons/Mail';
import axios from '../../services/axios';
import icons from '../../util/icons';
import { ALL_USER_ID } from '../../util/constants';
import FormDialogWrapper from '../dialogs/FormDialogWrapper';
import EventForm from '../forms/EventForm';
import InstanceForm from '../forms/InstanceForm';
import Routes from '../../util/routes';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  fab: {
    margin: theme.spacing(2)
  },
}));

const InstanceIndexTable = props => {
  const classes = useStyles();
  const { masqueradeId, token, refreshEvents, refreshInstances } = props;

  const [instances, setInstances] = useState([]);

  useEffect(() => {
    const getEventEmailsCount = async (instanceId) => {
      const endpoint = `/event-emails/countByInstance/${instanceId}`;
      const bearerToken = `Bearer ${token}`;
      const options = {
        headers: {
          Authorization: bearerToken
        }
      };

      try {
        const response = await axios.get(endpoint, options);
        let count = response.data.eventEmailsCount;
        return count;
      } catch (error) {
        return 0;
      }
    };

    const fetchInstances = async () => {
      const bearerToken = `Bearer ${token}`;
      const options = {
        headers: {
          Authorization: bearerToken
        }
      };

      let instances = [];
      try {
        const baseEndpoint = '/instances';
        const endpoint = masqueradeId === ALL_USER_ID ? baseEndpoint : `${baseEndpoint}/byTenant/${masqueradeId}`;
        const response = await axios.get(endpoint, options);
        instances = response.data.instances;

        if(instances) {
          for (let instance of instances) {
            instance.email_count = await getEventEmailsCount(instance.id);
          }
          setInstances(instances);
        }
      } catch (error) {
        instances = [];
        setInstances(instances);
      }
    };

    fetchInstances();
  }, [token, masqueradeId]);

  const handleCreateEvent = async payload => {
    const bearerToken = `Bearer ${token}`;
    const options = {
      headers: {
        Authorization: bearerToken
      }
    };
    try {
      const response = await axios.post('/events', payload, options);
      if (response.status === 201) {
        console.log('event created');
        refreshEvents();
        return true;
      }
    } catch (error) {
      console.log('Error in handeCreateEvent');
    }

    return false;
  };

  const handleCreateInstance = async payload => {
    try {
      const { token } = props;
      const bearerToken = `Bearer ${token}`;
      const config = {
        headers: {
          Authorization: bearerToken
        }
      };

      const response = await axios.post('/instances', payload, config);

      if (response.status === 201) {
        refreshInstances();
        return true;
      }
    } catch (err) {
      console.log('Error in create instance');
      console.log(err);
      return false;
    }

    return false;
  };

  const handleEditInstance = async payload => {
    try {
      const bearerToken = `Bearer ${token}`;
      const endpoint = `/instances/${payload.instanceId}`;
      const config = {
        headers: {
          Authorization: bearerToken
        }
      };

      const response = await axios.put(endpoint, payload, config);

      if (response.status === 200) {
        refreshInstances();
      }
    } catch (err) {
      console.log('Error in edit instance');
      console.log(err);
      return false;
    }
  };

  const renderEventIconButton = rowData => {
    return (
      <FormDialogWrapper
        button={({ handleClickOpen }) => {
          return (
            <Button color="secondary" onClick={handleClickOpen}>
              <EditIcon />
            </Button>
          )
        }}
        buttonText='Edit Instance'
        dialogTitle='Editing Instance'
        content={({handleClickOpen, handleClose}) => {
          return (
            <InstanceForm
              tenantId={rowData.tenant_id}
              instanceId={rowData.id}
              instanceType={rowData.instance_type}
              domain={rowData.domain}
              environment={rowData.environment}
              dataCenter={rowData.data_center}
              version={rowData.version}
              url={rowData.url}
              maintenanceSchedule={rowData.maintenance_schedule}
              onSubmit={handleEditInstance}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
            />
          )
        }}
      />
    );
  };

  const renderMailIconButton = rowData => {
    return rowData.email_count > 0 ? (
      <Link to={{
        pathname: Routes.eventEmailIndex,
        state: {
          instanceId: rowData.id
        }}}
      >
        <Button color="secondary">
          <MailIcon />
        </Button>
      </Link>
    ) :
    (
      <div>
        <Button color="secondary" disabled>
          <MailIcon />
        </Button>
      </div>
    );
  };

  const renderAddIconButton = rowData => {
    return(
      <FormDialogWrapper
        button={({ handleClickOpen }) => {
          return (
            <Button color="secondary" onClick={handleClickOpen}>
              <AddIcon />
            </Button>
          )
        }}
        buttonText={`Add Event`}
        dialogTitle='Add Event'
        content={({handleClickOpen, handleClose}) => {
          return (
            <EventForm
              instanceId={rowData.id}
              tenantId={rowData.tenant_id}
              onSubmit={handleCreateEvent}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
            />
          )
        }}
      />
    );
  };

  const columns = [
    {
      title: 'Actions',
      render: rowData => {
        return (
          <Box display="flex">
            { renderEventIconButton(rowData) }
            { renderMailIconButton(rowData) }
            { renderAddIconButton(rowData) }
          </Box>
        );
      }
    },
    { title: 'Tenant Name', field: 'tenant_name' },
    { title: 'Instance Type', field: 'instance_type' },
    { title: 'Instance Environment', field: 'environment' },
    { title: 'Data Center', field: 'data_center' },
    { title: 'Domain', field: 'domain' },
    { title: 'Version', field: 'version' },
    { title: 'Maintenance Schedule', field: 'maintenance_schedule' },
    { title: 'Email Count', field: 'email_count' },
  ];

  const instanceForm = props => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <MTableToolbar {...props} />
        <FormDialogWrapper
          button={({ handleClickOpen }) => {
            return (
              <Tooltip title="Add" aria-label="Add">
                <Fab color="primary" className={classes.fab} onClick={handleClickOpen}>
                  <AddIcon />
                </Fab>
              </Tooltip>
            );
          }}
          buttonText="Add Instance"
          dialogTitle='Adding Instance'
          content={({ handleClickOpen, handleClose }) => {
            return (
              <InstanceForm
                onSubmit={handleCreateInstance}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
              />
            );
          }}
        />
      </div>
    );
  }

  return (
    <main className={classes.main}>
      <MaterialTable
        columns={columns}
        data={instances}
        title='Instances'
        options={{
          filtering: true,
          grouping: false,
          search: false,
          padding: 'dense',
          pageSize: 5,
          pageSizeOptions: [5, 10, 25, 50, 100],
          emptyRowsWhenPaging: false,
          maxBodyHeight: '10%',
        }}
        icons={icons}
        components={{
          Toolbar: instanceForm,
        }}
      />
    </main>
  );
}

const mapStateToProps = ({ masqueradeId, token }) => {
  return { masqueradeId, token };
}

export default connect(mapStateToProps)(InstanceIndexTable);
