import React from 'react';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { logoutUserAction } from '../../actions';

const LogoutLink = props => {
  const { logoutUserAction } = props;
  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    logoutUserAction();
  };

  return (
    <List>
      <ListItem button onClick={handleLogout} >
        <ListItemText primary={'Logout'} />
      </ListItem>
    </List>
  );
};

const mapDispatchToProps = {
  logoutUserAction
};

export default connect(null, mapDispatchToProps)(LogoutLink);
