

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import EventEmailIndexTableRow from './EventEmailIndexTableRow';
import axios from '../../services/axios';

const useStyles = makeStyles(theme => ({
  main: {
    width: 'auto',
    display: 'block'
  },
  root: {
    width: 'auto',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: '100%',
  },
  toolbar: {
    paddingRight: theme.spacing(1)
  },
  spacer: {
    flex: '1 1 100%'
  },
  fab: {
    margin: theme.spacing(2)
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  },
  button: {
    margin: theme.spacing(1),
  }
}));

function EventEmailIndexTable(props) {
  const classes = useStyles();
  const { token, instanceId } = props;
  const [eventEmails, setEventEmails] = useState([]);

  useEffect(() => {
    const getEventEmails = async () => {
      const endpoint = `/event-emails/byInstance/${instanceId}`;
      const bearerToken = `Bearer ${token}`;
      const options = {
        headers: {
          Authorization: bearerToken
        }
      };

      let eventEmails = [];

      try {
        const response = await axios.get(endpoint, options);
        eventEmails = response.data.eventEmails;
        setEventEmails(eventEmails);
      } catch (error) {
        eventEmails = [];
        setEventEmails(eventEmails);
      }
    };

    getEventEmails();
  }, [token, instanceId]);


  const renderEventEmailRows = ({ eventEmails, classes }) => {
    if (eventEmails.length > 0) {
      return eventEmails.map(eventEmail => <EventEmailIndexTableRow key={eventEmail.id} classes={classes} eventEmail={eventEmail} />);
      } else {
      return <TableRow key={-1}><TableCell>No Data Found</TableCell></TableRow>
    }
  }

  return (
    <main className={classes.main}>
      <Paper className={classes.root}>
        <Toolbar className={classNames(classes.toolbar)}>
          <div className={classes.title}>
            <Typography variant="h6" id="tableTitle"></Typography>
          </div>
        </Toolbar>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Subject</TableCell>
              <TableCell>Received Date</TableCell>
              <TableCell>View Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderEventEmailRows({ classes, eventEmails })}
            </TableBody>
        </Table>
      </Paper>
    </main>
  );
}

const mapStateToProps = state => {
    return {
        token: state.token
    }
}

export default connect(mapStateToProps)(EventEmailIndexTable);
