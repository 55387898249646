import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import axios from '../../../services/axios';
import Routes from '../../../util/routes';
import { addSnackbarMessageAction } from '../../../actions/snackbarActions';


const useStyles = makeStyles(theme => ({
    main: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(6))]: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    passwordResetLink: {
      textDecoration: 'none',
      color: theme.palette.secondary.main,
      fontWeight: 600,
      "&:hover": {
        color: 'red',
      }
    },
  }));

  const PasswordUpdate = props => {
    const { addSnackbarMessageAction, token } = props;
    const classes = useStyles();

    const handlePasswordUpdateSubmission = async e => {
      e.preventDefault();

      const passwordInput = document.getElementById('current-password');
      const newPasswordInput = document.getElementById('new-password');
      const newPasswordConfirmationInput = document.getElementById('new-password-confirmation');

      const password = passwordInput.value;
      const newPassword = newPasswordInput.value;
      const newPasswordConfirmation = newPasswordConfirmationInput.value;

      if (newPassword !== newPasswordConfirmation) {
        addSnackbarMessageAction({ message: 'The passwords you entered do not match', variant: 'error' });
        return;
      }

      const payload = { password, newPassword };
      const bearerToken = `Bearer ${token}`;
      const config = {
        headers: {
          Authorization: bearerToken
        }
      };

      try {
        const response = await axios.post(Routes.updatePassword, payload, config);
        if (response.status === 200) {
          passwordInput.value = '';
          newPasswordInput.value = '';
          newPasswordConfirmationInput.value = '';
          addSnackbarMessageAction({ message: 'Password updated successfully', variant: 'success' });
        }
      } catch (err) {
        addSnackbarMessageAction({ message: 'Password updated failed', variant: 'error' });
      }
    }

    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">Password Update</Typography>
          <form className={classes.form} onSubmit={handlePasswordUpdateSubmission}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="current-password">Current Password</InputLabel>
              <Input name="current-password" type="password" id="current-password" autoComplete="off"/>
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">New Password</InputLabel>
              <Input name="new-password" type="password" id="new-password" autoComplete="off"/>
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">New Password Confirmation</InputLabel>
              <Input name="new-password-confirmation" type="password" id="new-password-confirmation" autoComplete="off"/>
            </FormControl>
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              Save
            </Button>
          </form>
        </Paper>
      </main>
    );
  }

const mapStateToProps = ({ token }) => {
  return { token };
};

const mapDispatchToProps = { addSnackbarMessageAction };

export default connect(mapStateToProps, mapDispatchToProps)(PasswordUpdate);
