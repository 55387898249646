import accessRules from '../../util/accessRules';

const checkAccess = ({ accessRules, userRole, performAction }) => {
  if (!userRole) {
    return false;
  }

  const { role, access } = userRole;

  if (access && access.includes(performAction)) {
    return true;
  }

  const permissions = accessRules[role];

  if (!permissions) {
    return false;
  }

  if (permissions.includes(performAction)) {
    return true;
  }

  return false;
}

const Can = props => {
  const { userRole, performAction } = props;

  if (checkAccess({ accessRules, userRole, performAction })) {
    return props.grantAccess();
  } else {
    return props.rejectAccess();
  }
};

export default Can;
