import React from 'react';
import PasswordUpdate from './settings/PasswordUpdate';

  const SettingsForm = props => {
    return (
      <PasswordUpdate />
    );
  }

export default SettingsForm;
