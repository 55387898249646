import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { setIsAuthenticatedAction, setTokenAction, setUserRoleAction } from '../../actions';
import axios from '../../services/axios';
import Routes from '../../util/routes';
import { addSnackbarMessageAction } from '../../actions/snackbarActions';


const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  passwordResetLink: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    fontWeight: 600,
    "&:hover": {
      color: 'red',
    }
  },
});

class Login extends React.Component {
  state = {
    redirectToReferrer: false,
    alertBase: null,
  }

  handleLogin = async e => {
    e.preventDefault();
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    const payload = {
      email: email,
      password: password
    };
    try {
      const response = await axios.post('/login', payload);
      const { roles, token } = response.data;
      const isAuthenticated = token ? true : false;
      const rolesString = JSON.stringify(roles);

      localStorage.setItem('accessToken', token);
      localStorage.setItem('roles', rolesString);

      this.props.setTokenAction(token);
      this.props.setIsAuthenticatedAction(isAuthenticated);
      this.props.setUserRoleAction(roles);

      this.setState({ redirectToReferrer: true });
    } catch (err) {
      this.props.addSnackbarMessageAction({ message: 'Login Attempt Failed', variant: 'error' });
      console.log(err);
      console.log(err.response.data.message);
    }
  }

  render() {
    const { isAuthenticated } = this.props;
    const { referrer } = this.props.location.state || { referrer: { pathname: '/ '} };
    const { redirectToReferrer } = this.state;

    if (isAuthenticated || redirectToReferrer) {
      return <Redirect to={referrer.pathname} />;
    }

    return (
      <main className={this.props.classes.main}>
        <Paper className={this.props.classes.paper}>
          <Avatar className={this.props.classes.avatar}>
            <IconButton />
          </Avatar>
          <Typography component="h1" variant="h5">Sign in</Typography>
          <form className={this.props.classes.form} onSubmit={this.handleLogin}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input id="email" name="email" autoComplete="email" autoFocus />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input name="password" type="password" id="password" autoComplete="current-password"/>
            </FormControl>
            <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me"/>
            <Button type="submit" fullWidth variant="contained" color="primary" className={this.props.classes.submit}>
              Sign in
            </Button>
            <Link to={Routes.requestPasswordReset} className={this.props.classes.passwordResetLink}>Reset Password</Link>
          </form>
        </Paper>
      </main>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.isAuthenticated
  };
};

const mapDispatchToProps = {
  setTokenAction,
  setIsAuthenticatedAction,
  setUserRoleAction,
  addSnackbarMessageAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));
