import { SNACKBAR_CLEAR, SNACKBAR_SUCCESS } from './types';

export const addSnackbarMessageAction = ({ message, variant }) => {
  return {
    type: SNACKBAR_SUCCESS,
    payload: {
      message,
      variant,
    }
  };
};

export const clearSnackbarAction = () => {
  return { type: SNACKBAR_CLEAR };
};

export default {
  addSnackbarMessageAction,
  clearSnackbarAction,
};
