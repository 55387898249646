import { SET_IS_AUTHENTICATED, SET_TOKEN, LOGOUT_USER, SET_MASQUERADE_ID, SET_USER_ROLE, SNACKBAR_SUCCESS } from './types';

export const setTokenAction = token => {
  return {
    type: SET_TOKEN,
    payload: {
      token
    }
  };
};

export const setIsAuthenticatedAction = isAuthenticated => {
  return {
    type: SET_IS_AUTHENTICATED,
    payload: {
      isAuthenticated
    }
  };
};


export const logoutUserAction = () => {
  return {
    type: LOGOUT_USER,
    payload: {
      isAuthenticated: false,
      roles: [],
      masqueradeId: -1
    }
  };
};

export const setMasqueradeIdAction = (id) => {
  return {
    type: SET_MASQUERADE_ID,
    payload: {
      masqueradeId: id
    }
  };
};

export const setUserRoleAction = (role) => {
  return {
    type: SET_USER_ROLE,
    payload: {
      role
    }
  };
};
