import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { createMuiTheme, MuiThemeProvider, CssBaseline } from '@material-ui/core';
import { connect } from 'react-redux';
import red from '@material-ui/core/colors/red';
import blueGrey from '@material-ui/core/colors/blueGrey';
import axios from '../services/axios';
import AdminPage from './admin/AdminPage';
import AppSnackbar from './ui/AppSnackbar';
import Home from './pages/Home';
import AppDrawer from './nav/AppDrawer';
import InstanceIndex from './instances/InstanceIndexPage';
import Login from './pages/Login';
import RequestPasswordReset from './pages/RequestPasswordReset';
import PasswordReset from './pages/PasswordReset';
import Routes from '../util/routes';
import EventEmailIndexPage from './eventEmails/EventEmailIndexPage';
import TenantIndex from './tenants/TenantIndexPage';
import EssJobIndexPage from './essJobs/EssJobsIndexPage';
import EssJobShowPage from './essJobs/EssJobsShowPage';
import EssJobUploadShow from './essJobs/EssJobUploadShow';
import EventIndexPage from './events/EventIndexPage';
import EventShow from './events/EventShow';
import EventDashboard from './events/EventDashboardPage';
import Edi753HeadersReport from './reports/edi/Edi753HeadersReport';
import Edi754HeadersReport from './reports/edi/Edi754HeadersReport';
import Edi810HeadersReport from './reports/edi/Edi810HeadersReport';
import Edi850HeadersReport from './reports/edi/Edi850HeadersReport';
import Edi940HeadersReport from './reports/edi/Edi940HeadersReport';
import Edi945HeadersReport from './reports/edi/Edi945HeadersReport';
import EssJobUploadPage from './essJobs/EssJobUploadPage';
import SettingsPage from './pages/SettingsPage';
import { setTokenAction, setIsAuthenticatedAction, setUserRoleAction } from '../actions';
import PrivateRoute from './auth/PrivateRoute';
import Can from './auth/Can';


const theme = createMuiTheme({
  palette: {
    primary: red,
    secondary: blueGrey
  },
  typography: {
    useNextVariants: true
  }
});

class App extends React.Component {
  componentDidMount = () => {
    let token = localStorage.getItem('accessToken');

    if (token) {
      const isAuthenticated = true;
      this.props.setTokenAction(token);
      this.props.setIsAuthenticatedAction(isAuthenticated);
      let roles = localStorage.getItem('roles');
      const roleString = JSON.parse(roles);
      if (roleString) {
        this.props.setUserRoleAction(roleString);
      } else {
        const getUserRoles = async () => {
          const bearerToken = `Bearer ${token}`;
          const options = {
            headers: {
              Authorization: bearerToken
            }
          };

          let role;

          try {
            const response = await axios.get('/getUserRoles', options);
            role = response.data;
            this.props.setUserRoleAction(role);
          } catch (err) {
            console.log(err.message);
            throw err;
          }
        };

        getUserRoles();
      }
    }
  }

  render() {
    return (
      <>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <div style={{ marginTop: theme.spacing(12)}}>
            <AppSnackbar />
            <AppDrawer isAuthenticated={this.props.isAuthenticated} userRole={this.props.userRole}>
              <Switch>
                <Route exact path={Routes.home} component={() => <Home isAuthenticated={this.props.isAuthenticated} />} />

                <Route path={Routes.login} component={Login} />
                <Route exact path={Routes.requestPasswordReset} component={RequestPasswordReset} />
                <Route path={Routes.passwordReset} component={PasswordReset} />

                <PrivateRoute
                  exact
                  path={Routes.eventDash}
                  component={() => {
                    return (
                      <Can
                        grantAccess={() => <EventDashboard />}
                        rejectAccess={() => <Redirect to={{ pathname: Routes.home }} /> }
                        userRole={this.props.userRole}
                        performAction='view:events'
                      />
                    )
                  }}
                />

                <PrivateRoute
                  exact
                  path={Routes.admin}
                  component={() => {
                    return (
                      <Can
                        grantAccess={() => <AdminPage />}
                        rejectAccess={() => <Redirect to={{ pathname: Routes.home }} /> }
                        userRole={this.props.userRole}
                        performAction='view:admin'
                      />
                    )
                  }}
                />

                <PrivateRoute
                  exact
                  path={Routes.essJobIndex}
                  component={() => {
                    return (
                      <Can
                        grantAccess={() => <EssJobIndexPage />}
                        rejectAccess={() => <Redirect to={{ pathname: Routes.home }} /> }
                        userRole={this.props.userRole}
                        performAction='view:admin'
                      />
                    )
                  }}
                />

                <PrivateRoute
                  path={Routes.essJobShow}
                  component={EssJobShowPage}
                />

                <PrivateRoute
                  exact
                  path={Routes.instanceIndex}
                  component={() => {
                    return (
                      <Can
                        grantAccess={() => <InstanceIndex />}
                        rejectAccess={() => <Redirect to={{ pathname: Routes.home }} /> }
                        userRole={this.props.userRole}
                        performAction='view:admin'
                      />
                    )
                  }}
                />

                <PrivateRoute
                  exact
                  path={Routes.tenantIndex}
                  component={() => {
                    return (
                      <Can
                        grantAccess={() => <TenantIndex />}
                        rejectAccess={() => <Redirect to={{ pathname: Routes.home }} /> }
                        userRole={this.props.userRole}
                        performAction='view:tenants'
                      />
                    )
                  }}
                />

                <PrivateRoute
                  exact
                  path={Routes.eventIndex}
                  component={() => {
                    return (
                      <Can
                        grantAccess={() => <EventIndexPage />}
                        rejectAccess={() => <Redirect to={{ pathname: Routes.home }} /> }
                        userRole={this.props.userRole}
                        performAction='view:events'
                      />
                    )
                  }}
                />

                <PrivateRoute
                  exact
                  path={Routes.eventEmailIndex}
                  component={() => {
                    return (
                      <Can
                        grantAccess={() => <EventEmailIndexPage />}
                        rejectAccess={() => <Redirect to={{ pathname: Routes.home }} /> }
                        userRole={this.props.userRole}
                        performAction='view:events'
                      />
                    )
                  }}
                />

                <PrivateRoute
                  path={Routes.eventShow}
                  component={props => {
                    return (
                      <Can
                        grantAccess={() => <EventShow {...props} />}
                        rejectAccess={() => <Redirect to={{ pathname: Routes.home }} /> }
                        userRole={this.props.userRole}
                        performAction='view:events'
                      />
                    )
                  }}
                />

                <PrivateRoute
                  path={Routes.edi753Report}
                  component={() => {
                    return (
                      <Can
                        grantAccess={() => <Edi753HeadersReport />}
                        rejectAccess={() => <Redirect to={{ pathname: Routes.home }} /> }
                        userRole={this.props.userRole}
                        performAction='view:reports'
                      />
                    )
                  }}
                />

                <PrivateRoute
                  path={Routes.edi754Report}
                  component={() => {
                    return (
                      <Can
                        grantAccess={() => <Edi754HeadersReport />}
                        rejectAccess={() => <Redirect to={{ pathname: Routes.home }} /> }
                        userRole={this.props.userRole}
                        performAction='view:reports'
                      />
                    )
                  }}
                />

                <PrivateRoute
                  path={Routes.edi810Report}
                  component={() => {
                    return (
                      <Can
                        grantAccess={() => <Edi810HeadersReport />}
                        rejectAccess={() => <Redirect to={{ pathname: Routes.home }} /> }
                        userRole={this.props.userRole}
                        performAction='view:reports'
                      />
                    )
                  }}
                />

                <PrivateRoute
                  path={Routes.edi850Report}
                  component={props => {
                    return (
                      <Can
                        {...props}
                        grantAccess={() => <Edi850HeadersReport />}
                        rejectAccess={() => <Redirect to={{ pathname: Routes.home }} /> }
                        userRole={this.props.userRole}
                        performAction='view:reports'
                      />
                    )
                  }}
                />

                <PrivateRoute
                  path={Routes.edi940Report}
                  component={() => {
                    return (
                      <Can
                        grantAccess={() => <Edi940HeadersReport />}
                        rejectAccess={() => <Redirect to={{ pathname: Routes.home }} /> }
                        userRole={this.props.userRole}
                        performAction='view:reports'
                      />
                    )
                  }}
                />

                <PrivateRoute
                  path={Routes.edi945Report}
                  component={() => {
                    return (
                      <Can
                        grantAccess={() => <Edi945HeadersReport />}
                        rejectAccess={() => <Redirect to={{ pathname: Routes.home }} /> }
                        userRole={this.props.userRole}
                        performAction='view:reports'
                      />
                    )
                  }}
                />

                <PrivateRoute
                  exact
                  path={Routes.essJobsUpload}
                  component={() => {
                    return (
                      <Can
                        grantAccess={() => <EssJobUploadPage token={this.props.token} masqueradeId={this.props.masqueradeId} />}
                        rejectAccess={() => <Redirect to={{ pathname: Routes.home }} /> }
                        userRole={this.props.userRole}
                        performAction='view:ess-job-upload'
                      />
                    )
                  }}
                />

                <PrivateRoute
                  path={Routes.essJobsUploadShow}
                  component={props => {
                    return (
                      <Can
                        grantAccess={() => <EssJobUploadShow {...props} />}
                        rejectAccess={() => <Redirect to={{ pathname: Routes.home }} /> }
                        userRole={this.props.userRole}
                        performAction='view:ess-job-upload'
                      />
                    )
                  }}
                />

                <PrivateRoute
                  path={Routes.settings}
                  component={props => {
                    return (
                      <Can
                        grantAccess={() => <SettingsPage {...props} />}
                        rejectAccess={() => <Redirect to={{ pathname: Routes.home }} /> }
                        userRole={this.props.userRole}
                        performAction='view:ess-job-upload'
                      />
                    )
                  }}
                />

              </Switch>
            </AppDrawer>
          </div>
        </MuiThemeProvider>
      </>
    );
  }
};

const mapStateToProps = (state) => {
  const { isAuthenticated, token, userRole, masqueradeId } = state;

  return {
    isAuthenticated,
    token,
    userRole,
    masqueradeId,
  };
}

const mapDispatchToProps = {
  setTokenAction,
  setIsAuthenticatedAction,
  setUserRoleAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
