exports.home = '/';
exports.reportExample = '/report-example';
exports.login = '/login';
exports.admin = '/admin';
exports.createTenant = '/create-tenant';
exports.createUser = '/create-user';
exports.instanceIndex = '/instances';
exports.tenantIndex = '/tenants';
exports.essJobIndex = '/ess-jobs';
exports.essJobShow = '/ess-jobs/:essJobName';
exports.eventIndex = '/events';
exports.eventDash = '/event-dashboard'
exports.eventEmailIndex = '/event-emails';
exports.eventShowBase = '/events';
exports.eventShow = `/events/:eventId`;
exports.edi753Report = '/reports/edi-753-report';
exports.edi754Report = '/reports/edi-754-report';
exports.edi810Report = '/reports/edi-810-report';
exports.edi850Report = '/reports/edi-850-report';
exports.edi940Report = '/reports/edi-940-report';
exports.edi945Report = '/reports/edi-945-report';
exports.essJobsUpload = '/ess-jobs-upload';
exports.essJobsUploadShow = '/ess-jobs-upload/:fileId';
exports.essJobsUploadEndpoint='/ess-jobs/essJobFileUpload';
exports.essJobsLines = '/ess-jobs/lines'
exports.essJobFile = '/ess-jobs/essJobFileUpload';
exports.requestPasswordReset = '/reset-password';
exports.passwordReset = '/reset-password/:resetToken';
exports.setNewPassword = '/set-new-password';
exports.updatePassword = '/users/update-password';
exports.settings = '/settings';
