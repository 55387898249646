import { SET_IS_AUTHENTICATED, SET_TOKEN, LOGOUT_USER, SET_USER_ROLE } from '../actions/types';

const  initialState = {
    token: '',
    isAuthenticated: false,
    userRole: null,
};

export function tokenReducer(state = initialState.token, action) {
  switch (action.type) {
    case SET_TOKEN:
      const token = action.payload.token;
      return token;
    case LOGOUT_USER:
      const unsetToken = '';
        return unsetToken;
    default:
      return state;
  }
};

export function isAuthenticatedReducer(state = initialState.isAuthenticated, action) {
  switch (action.type) {
    case SET_IS_AUTHENTICATED:
    case LOGOUT_USER:
      const isAuthenticated = action.payload.isAuthenticated;
      return isAuthenticated;
    default:
      return state;
  }
};

export function userRoleReducer(state = initialState.userRole, action) {
  switch (action.type) {
    case SET_USER_ROLE:
      const newRole = action.payload.role;
      return newRole;
    case LOGOUT_USER:
        return null;
    default:
      return state;
  }
};
