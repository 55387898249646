import React from 'react';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Routes from '../../util/routes';


const ReportLinks = props => {
  return (
    <>
      <List
        subheader={
          <ListSubheader component="div">
            Report
          </ListSubheader>
        }
      >
        <ListItem button component={Link} to={Routes.edi753Report}>
          <ListItemText primary={'753 Transactions'} />
        </ListItem>
        <ListItem button component={Link} to={Routes.edi754Report}>
          <ListItemText primary={'754 Transactions'} />
        </ListItem>
        <ListItem button component={Link} to={Routes.edi810Report}>
            <ListItemText primary={'810 Transactions'} />
          </ListItem>
        <ListItem button component={Link} to={Routes.edi850Report}>
          <ListItemText primary={'850 Transactions'} />
        </ListItem>
        <ListItem button component={Link} to={Routes.edi940Report}>
          <ListItemText primary={'940 Transactions'} />
        </ListItem>
        <ListItem button component={Link} to={Routes.edi945Report}>
            <ListItemText primary={'945 Transactions'} />
          </ListItem>
        </List>
    </>
  );
}

export default ReportLinks;
