import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

function DialogWrapper(props) {
  const { dialogActionButton, content, button, buttonText, dialogTitle, maxWidth } = props;
  const [open, setOpen] = React.useState(false);

  const renderDialogAction = () => {
    if (!dialogActionButton) {
      return null;
    }

    return (
      <DialogActions>
        { dialogActionButton }
      </DialogActions>
    );
  }

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div>
      {button && button({buttonText, handleClickOpen})}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-wrapper-title"
        fullWidth={true}
        maxWidth={maxWidth ? maxWidth : 'md'}
      >
        <DialogTitle id="dialog-wrapper-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          {content}
        </DialogContent>
        { renderDialogAction() }
      </Dialog>
    </div>
  );
}

export default DialogWrapper;
