import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import EditEventForm from '../forms/EditEventForm';
import ActivityIndexTable from '../activities/ActivityIndexTable';
import myAxios from '../../services/axios';
import axios from 'axios';


const useStyles = makeStyles(theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  }
}));


const EventShow = props => {
  const classes = useStyles();
  const { masqueradeId, token } = useSelector( state => state );
  const { eventId } = props.match.params;

  const [event, setEvent] = useState({});

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const fetchEvent = () => {
      const bearerToken = `Bearer ${token}` ;
      const endpoint = `/events/${eventId}`;
      const options = {
        headers: {
          Authorization: bearerToken
        },
        cancelToken: source.token,
      };

      myAxios
        .get(endpoint, options)
        .then( ({ data }) => {
          if (data && data.event) {
            const { event } = data;
            setEvent(event);
          }
        })
        .catch(err => {
          console.log('An error occured while retrieving events');
        });
    }

    fetchEvent();
    return () => source.cancel();
  }, [token, masqueradeId, eventId]);

  const [assignableUsers, setAssignableUsers] = useState([]);

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const fetchAssignableUsers = () => {
      const bearerToken = `Bearer ${token}` ;
      const endpoint = `/users/assignable`;
      const options = {
        headers: {
          Authorization: bearerToken
        },
        cancelToken: source.token,
      };

      myAxios
        .get(endpoint, options)
        .then( ({ data }) => {
          if (data && data.users) {
            const { users } = data;
            setAssignableUsers(users);
          }
        })
        .catch(err => {
          console.log('An error occured while retrieving assignable users');
        });
    }

    fetchAssignableUsers();
    return () => source.cancel();
  }, [token, eventId]);

  const [eventStatuses, setEventStatuses] = useState([]);

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const fetchEventStatuses = () => {
      const endpoint = `/lookups/eventStatus`;
      const bearerToken = `Bearer ${token}`;
      const options = {
        headers: {
          Authorization: bearerToken
        },
        cancelToken: source.token,
      };

      myAxios
        .get(endpoint, options)
        .then( ({ data }) => {
          if (data && data.lookups) {
            const lookups = data.lookups;
            setEventStatuses(lookups);
          }
        })
        .catch(err => {
          console.log('An error occured while retrieving event statuses');
          console.log(err);
        });
    };

    fetchEventStatuses();
    return () => source.cancel();
  }, [token, eventId]);

  const [eventTypes, setEventTypes] = useState([]);

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const fetchEventTypes = () => {
      const endpoint = `/lookups/eventType`;
      const bearerToken = `Bearer ${token}`;
      const options = {
        headers: {
          Authorization: bearerToken
        },
        cancelToken: source.token,
      };

      myAxios
        .get(endpoint, options)
        .then( ({ data }) => {
          if (data && data.lookups) {
            const lookups = data.lookups;
            setEventTypes(lookups);
          }
        })
        .catch(err => {
          console.log('An error occured while retrieving event statuses');
          console.log(err);
        });
    };

    fetchEventTypes();
    return () => source.cancel();
  }, [token, eventId]);

  return (
    <main className={classes.main}>
      <Typography variant="h5" component="h5">
        Event Info
      </Typography>
      <Paper>
        <EditEventForm
          event={event}
          assignableUsers={assignableUsers}
          eventStatuses={eventStatuses}
          eventTypes={eventTypes}
        />
      </Paper>
      <ActivityIndexTable
        token={token}
        eventId={eventId}
      />
    </main>
  );
}

export default EventShow;
