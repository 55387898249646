import React from 'react';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Routes from '../../util/routes';


const LoginLink = props => {
  return (
    <List>
      <ListItem button component={Link} to={Routes.login}>
        <ListItemText primary={'Login'} />
      </ListItem>
    </List>
  );
};

export default LoginLink;
