import React, { useEffect, useState } from 'react';
import axios from '../../services/axios';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import FormDialogWrapper from '../dialogs/FormDialogWrapper';
import TenantForm from '../forms/TenantForm';
import TenantsIndexTableRow from './TenantsIndexTableRow';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  },
  toolbar: {
    paddingRight: theme.spacing(1)
  },
  spacer: {
    flex: '1 1 100%'
  },
  fab: {
    margin: theme.spacing(2)
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  }
}));

function TenantsIndexTable(props) {
  const classes = useStyles();
  const { token } = props;

  const [ tenants, setTenants ] = useState([]);
  const [refreshFlag, setRefreshFlag] = useState(true);

  useEffect(() => {
    const fetchTenantData = async () => {
      const bearerToken = `Bearer ${token}` ;
      const options = {
        headers: {
          Authorization: bearerToken
        }
      };
      const response = await axios.get('/tenants', options);
      const tenants = response.data.tenants;
      setTenants(tenants);
    }

    fetchTenantData();
  }, [token, refreshFlag]);

  const handleCreateTenant = async ({ tenantName, tenantAdminEmail, tenantPlanType, tenantMailbox }) => {
    try {
      const data = { tenantName, tenantAdminEmail, tenantPlanType, tenantMailbox };
      const bearerToken = `Bearer ${token}`;
      const config = {
        headers: {
          Authorization: bearerToken
        }
      };

      const response = await axios.post('/tenants', data, config);

      if (response.status === 201) {
        console.log('create tenant success');
        setRefreshFlag(!refreshFlag);
        return true;
      }
    } catch (err) {
      console.log('Error in handleCreateTenant');
      return false;
    }
  }

  const handleEditTenant = async ({ tenantId, tenantName, tenantAdminEmail, tenantPlanType, tenantMailbox }) => {
    try {
      const data = { tenantId, tenantName, tenantAdminEmail, tenantPlanType, tenantMailbox };
      const endpoint = `/tenants/${tenantId}`;
      const bearerToken = `Bearer ${token}`;
      const config = {
        headers: {
          Authorization: bearerToken
        }
      };
      const response = await axios.put(endpoint, data, config);
      if (response.status === 200) {
        console.log('edit tenant success');
        setRefreshFlag(!refreshFlag);
        return true;
      }
    } catch (err) {
      console.log('Error in handleEditTenant');
      return false;
    }
  }

  return (
    <>
      <Paper className={classes.root}>
        <Toolbar className={classNames(classes.toolbar)}>
          <div className={classes.title}>
            <Typography variant="h6" id="tableTitle">Tenants</Typography>
          </div>
          <div className={classes.spacer} />
          <div className={classes.actions}>
              <FormDialogWrapper
                button={({ handleClickOpen }) => {
                  return (
                    <Tooltip title="Add" aria-label="Add">
                      <Fab color="primary" className={classes.fab} onClick={handleClickOpen}>
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                  )
                }}
                buttonText='Add Tenant'
                dialogTitle={`Add a tenant`}
                content={({handleClickOpen, handleClose}) => {
                  return (
                    <TenantForm
                      onSubmit={handleCreateTenant}
                      handleClickOpen={handleClickOpen}
                      handleClose={handleClose}
                    />
                  )
                }}
              />
          </div>
        </Toolbar>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Tenant Name</TableCell>
              <TableCell>Account Manager</TableCell>
              <TableCell>Manager Email</TableCell>
              <TableCell>Plan</TableCell>
              <TableCell>Mailbox</TableCell>
              <TableCell># of Users</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { tenants.map(row => <TenantsIndexTableRow key={row.id} row={row} onSubmit={handleEditTenant} />) }
          </TableBody>
        </Table>
      </Paper>
    </>
  );
}

const mapStateToProps = (state) => {
  const { token } = state;

  return {
    token
  };
}


export default connect(mapStateToProps)(TenantsIndexTable);
