import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  card: {
    minWidth: 300,
    borderTop: '3px solid #F44336',
  },
  instanceType: {
    fontSize: '120%',
  },
  instanceEnvironment: {
    fontSize: '60%',
    marginLeft: '2px',
    color: '#606060',
    fontWeight: '600',
  },
  actions: {
    backgroundColor: '#f7f7f7',
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

function InstanceCard(props) {
  const classes = useStyles();
  const { environment, instance_type, url } = props.instance;

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h5" component="h2">
          <div className={classes.instanceType}>
            {instance_type}
          </div>
          <div className={classes.instanceEnvironment}>
            {environment}
          </div>
        </Typography>
      </CardContent>
      <CardActions
        className={classes.actions}>
        <Button
          component={Link}
          size="small"
          color="secondary"
          target="_blank"
          href={url}
          variant="contained"
          className={classes.button}
        >
          Go To
        </Button>
      </CardActions>
    </Card>
  );
}

export default InstanceCard;
