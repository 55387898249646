import React from 'react';
import { makeStyles } from '@material-ui/styles';
import AdminLinks from './AdminLinks';
import HomeLink from './HomeLink';
import ReportLinks from './ReportLinks';
import EssJobLinks from './EssJobLinks';
import LoginLink from './LoginLink';
import LogoutLink from './LogoutLink';
import SettingsLink from './SettingsLink';


const useStyles = makeStyles(theme => ({
  linkSection: {
    marginTop: theme.spacing(5),
  },
}));


const DrawerLinks = props => {
  const classes = useStyles();
  const { isAuthenticated, handleClick } = props;

  return (
    <div onClick={handleClick}>
      { isAuthenticated ?
        (
          <>
            <HomeLink className={classes.linkSection} />
            <AdminLinks className={classes.linkSection} />
            <ReportLinks className={classes.linkSection} />
            <EssJobLinks className={classes.linkSection} />
            <SettingsLink />
            <LogoutLink />
          </>
        ) :
        (
          <>
            <LoginLink />
          </>
        )
      }
    </div>
  );
};

export default DrawerLinks;
