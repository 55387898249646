import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import moment from 'moment';
import axios from '../../../services/axios';
import icons from '../../../util/icons';


const Edi945Lines = props => {
  const { jtTransactionId, token } = props;
  const [lineData, setLineData] = useState([]);

  const columns = [
    { title: "Item", field: 'item' },
    { title: "Cases Shipped", field: 'cases_shipped' },
    { title: "Units Shipped", field: 'units_shipped' },
    { title: "Oracle Requested Qty", field: "requested_quantity" },
    { title: "Oracle Shipment Line Id", field: 'oracle_shipment_line_id' },
    { title: "Oracle Shipment", field: 'oracle_shipment' },
    { title: "Available To Transact", field: "available_to_transact" },
    { title: "On Hand", field: "onhand" },
    { title: "Process Status", field: "process_status" },
    { title: "Process Message", field: "process_message" },
    {
      title: "Creation Date",
      field: "created_at",
      defaultSort: "desc",
      render: rowData => {
        if (rowData.created_at !== null) {
          return moment(rowData.created_at).format('MM/DD/YYYY h:mm:ss a');
        }
      }
    },
  ];


  useEffect(() => {
    const fetch850Headers = async () => {
      const endpoint = `/reports/945-lines/${jtTransactionId}`;
      const bearerToken = `Bearer ${token}`;
      const options = {
        headers: {
          Authorization: bearerToken
        }
      };

      let lines = [];
      try {
        const response = await axios.get(endpoint, options);
        lines = response.data.lines;
        setLineData(lines);
      } catch (error) {
        lines = [];
        setLineData(lines);
      }
    };

    fetch850Headers();
  }, [jtTransactionId, token]);

  return (
    <MaterialTable
      columns={columns}
      data={lineData}
      doubleHorizontalScroll={true}
      icons={icons}
      options={{
        doubleHorizontalScroll: true,
        filtering: true,
        paging: false,
        search: false,
        showTitle: false,
        sorting: false,
        title: false,
        toolbar: false,
      }}
    />
  );
};

const mapStateToProps = (state) => {
  const { token } = state;

  return {
    token
  };
}

export default connect(mapStateToProps)(Edi945Lines);
