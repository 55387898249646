import { combineReducers } from 'redux';
import { isAuthenticatedReducer, tokenReducer, userRoleReducer } from './auth';
import { masqueradeIdReducer } from './masquerade';
import { uiSnackbarMessageReducer, uiSnackbarStateReducer } from './ui';

const rootReducer = combineReducers({
  token: tokenReducer,
  isAuthenticated: isAuthenticatedReducer,
  masqueradeId: masqueradeIdReducer,
  userRole: userRoleReducer,
  snackbarMessage: uiSnackbarMessageReducer,
  snackbarState: uiSnackbarStateReducer,
});

export default rootReducer;
