import React, { useEffect, useState } from 'react';
import axios from '../../services/axios';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import EssJobSummaryTableRow from './EssJobSummaryTableRow';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  },
  toolbar: {
    paddingRight: theme.spacing(1)
  },
  spacer: {
    flex: '1 1 100%'
  },
  fab: {
    margin: theme.spacing(2)
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  }
}));

function EssJobSummaryTable(props) {
  const classes = useStyles();
  const { essJobName, masqueradeId, token } = props;
  const [ essJobs, setEssJobs ] = useState([]);

  useEffect(() => {
    const fetchEssJobs = async () => {
      let essJobs = [];
      try {
        const bearerToken = `Bearer ${token}` ;
        const endpoint = `/ess-jobs/${masqueradeId}/job-history/${essJobName}`;
        const options = {
          headers: {
            Authorization: bearerToken
          }
        };
        const response = await axios.get(endpoint, options);
        essJobs = response.data.essJobs;
        setEssJobs(essJobs);
      } catch (error) {
        essJobs = [];
        setEssJobs([]);
      }
    }

    fetchEssJobs();
  }, [token, masqueradeId, essJobName]);

  return (
    <main>
      <Paper className={classes.root}>
        <Toolbar className={classNames(classes.toolbar)}>
          <div className={classes.title}>
            <Typography variant="h6" id="tableTitle">Ess Jobs History</Typography>
          </div>
        </Toolbar>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Request ID</TableCell>
              <TableCell>Start Time</TableCell>
              <TableCell>End Time</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { essJobs.length > 0 ?
              essJobs.map(essJob => <EssJobSummaryTableRow key={essJob.requestid} essJob={essJob} />) :
              <TableRow><TableCell>No Data Found</TableCell></TableRow>
            }
          </TableBody>
        </Table>
      </Paper>
    </main>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    masqueradeId: state.masqueradeId
  };
}

export default connect(mapStateToProps)(EssJobSummaryTable);
