import React from 'react';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import { formatDateTime } from '../../util/helpers';

function EssJobsIndexTableRow(props) {
  const { classes, essJob, handleClick } = props;

  return (
    <>
      <TableRow>
        <TableCell><Link to={`/ess-jobs/${essJob.job_name}`}>{essJob.job_name}</Link></TableCell>
        <TableCell>{formatDateTime(essJob.last_run_start)}</TableCell>
        <TableCell>{formatDateTime(essJob.last_run_end)}</TableCell>
        <TableCell>{essJob.last_run_status}</TableCell>
        <TableCell>{essJob.num_runs}</TableCell>
        <TableCell>{`${essJob.avg_runtime.seconds} seconds`}</TableCell>
        <TableCell>{essJob.type}</TableCell>
        <TableCell>
          <Button display="block" type="submit" variant="contained" color="primary" className={classes.submit} onClick={handleClick(essJob.definition)}>
            Refresh
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
}

export default EssJobsIndexTableRow;
