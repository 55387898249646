import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import MailIcon from '@material-ui/icons/Mail';
import moment from 'moment';
import axios from '../../services/axios';
import icons from '../../util/icons';
import { ALL_USER_ID } from '../../util/constants';
import FormDialogWrapper from '../dialogs/FormDialogWrapper';
import Routes from '../../util/routes';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  button: {
    margin: theme.spacing(1),
  },
  stale: {
    backgroundColor: 'lightcoral',
  }
}));

const renderEventIconButton = rowData => {
  return (
    <IconButton
      color="secondary"
      component={Link}
      to={`${Routes.eventShowBase}/${rowData.id}`}
    >
      <EditIcon />
    </IconButton>
  );
};

const renderMailIconButton = rowData => {
  if (rowData.email_content !== null && rowData.email_content.length > 0) {
    return (
      <FormDialogWrapper
        button={({ handleClickOpen }) => {
          return (
            <IconButton color="secondary" onClick={handleClickOpen}>
              <MailIcon />
            </IconButton>
          )
        }}
        dialogTitle='Event Email'
        content={({ handleClickOpen, handleClose }) => {
          return (
            <div
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
            >
              <div dangerouslySetInnerHTML={{ __html: rowData.email_content }} />
            </div>
          )
        }}
      />
    );
  }
  return (
    <IconButton disabled>
      <MailIcon />
    </IconButton>
  );
};

const renderStatusIcon = rowData => {
  const stateColors = {
    success: { bgColor: '#E6FFFA', color: '#234E52' },
    danger: { bgColor: '#FFF5F5', color: '#C53030' },
    warn: { bgColor: '#FFFAF0', color: '#C05621' },
  };

  let stateColor = stateColors.danger;

  if (!rowData.status || rowData.status === 'none') {
    stateColor = stateColors.danger;
  } else if (rowData.status === 'stale') {
    stateColor = stateColors.warn;
  } else if (rowData.status === 'new' ) {
    stateColor = stateColors.success;
  }
  const style = {
    borderRadius: '100px',
    backgroundColor: stateColor.bgColor,
    color: stateColor.color,
    fontWeight: 700,
    textAlign: 'center',
  };

  const content = !rowData.status  || rowData.status === 'none' ? 'No Activity' : rowData.status;

  return (
    <div
      height={'50px'}
      width={'50px'}
      style={style}
    >
      { content }
    </div>);
};

const columns = [
  {
    title: 'Actions',
    render: rowData => {
      return (
        <Box display="flex">
          { renderEventIconButton(rowData) }
          { renderMailIconButton(rowData) }
        </Box>
      );
    }
  },
  { title: 'Status', render: rowData => renderStatusIcon(rowData) },
  { title: 'Tenant Name', field: 'tenant_name' },
  { title: 'Event Type', field: 'event_type_name' },
  {
    title: 'Event Date',
    field: 'event_date',
    render: rowData => rowData.event_date ? moment(rowData.event_date).format('l') : null,
  },
  { title: 'Release Version', field: 'release_version' },
  { title: 'Status', field: 'event_status' },
  { title: 'Email Subject', field: 'email_subject' },
  { title: 'Created By', field: 'created_by' },
];

const EventIndexMaterialTable = props => {
  const classes = useStyles();
  const { masqueradeId, token } = props;

  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      const bearerToken = `Bearer ${token}`;
      const options = {
        headers: {
          Authorization: bearerToken
        }
      };

      let events = [];
      try {
        const baseEndpoint = '/events';
        const endpoint = masqueradeId === ALL_USER_ID ? baseEndpoint : `${baseEndpoint}/byTenant/${masqueradeId}`;
        const response = await axios.get(endpoint, options);
        events = response.data.events;
        if (events) {
          setEvents(events);
        }
      } catch (error) {
        events = [];
        setEvents(events);
      }
    };

    fetchEvents();
  }, [token, masqueradeId]);

  return (
    <main className={classes.main}>
      <MaterialTable
        columns={columns}
        data={events}
        title='Events'
        options={{
          filtering: true,
          grouping: false,
          search: false,
          padding: 'dense',
          pageSize: 5,
          pageSizeOptions: [5, 10, 25, 50, 100],
          emptyRowsWhenPaging: false,
          maxBodyHeight: '10%',
        }}
        icons={icons}
      />
    </main>
  );
};

const mapStateToProps = ({ masqueradeId, token }) => {
  return { masqueradeId, token };
}

export default connect(mapStateToProps)(EventIndexMaterialTable);
