import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { formatDateTime } from '../../util/helpers';

function EssJobsIndexTableRow(props) {
  const { essJob } = props;
  return (
    <>
      <TableRow>
        <TableCell>{essJob.requestid}</TableCell>
        <TableCell>{formatDateTime(essJob.processstart)}</TableCell>
        <TableCell>{formatDateTime(essJob.processend)}</TableCell>
        <TableCell>{essJob.executable_status}</TableCell>
        <TableCell>{essJob.type}</TableCell>
      </TableRow>
    </>
  );
}

export default EssJobsIndexTableRow;
