import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import withStyles from '@material-ui/core/styles/withStyles';


const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: '60%',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(2),
    display: 'block',
    alignSelf: 'center'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '60%'
  },
  datepickerContainer: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '60%'
  },
  textAreaInput: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%'
  },
  dense: {
    marginTop: 19
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '60%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
  input: {
    width: '50%'
  },
  formElements: {
    width: '100%',
    height: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
});

class ActivityForm extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = props.onSubmit.bind(this);

    this.state = {
      eventId: this.props.eventId,
      activityType: this.props.activity ? this.props.activity.type : '',
      activityNotes: this.props.activity ? this.props.activity.notes : '',
      activityDate: this.props.activity ? this.props.activity.date : new Date(),
    };
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const activity = {
      activityDate: this.state.activityDate,
      activityNotes: this.state.activityNotes,
      activityType: this.state.activityType,
      eventId: this.state.eventId
    };
    const success = await this.onSubmit(activity);
    if (success) {
      this.props.handleClose();
    }
  };

  render() {
    const numInputRows = 5;
    return (
      <form className={this.props.classes.form} onSubmit={this.handleSubmit}>
        <div className={this.props.classes.formElements}>
          <div className={this.props.classes.datepickerContainer}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="activity-date-field"
              label="Activity Date"
              value={this.state.activityDate}
              onChange={date => {
                this.setState({ activityDate: date });
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider></div>
          <TextField
            required
            id="activity-type-field"
            label='Activity Type'
            className={this.props.classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            value={this.state.activityType}
            onChange={this.handleChange('activityType')}
          />
          <TextField
            required
            id="activity-status-field"
            label='Activity Status'
            className={this.props.classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            value={this.state.activityStatus}
            onChange={this.handleChange('activityStatus')}
          />
          <FormControl className={this.props.classes.formControl}>
            <InputLabel htmlFor="activity-notes-field">Notes</InputLabel>
            <Input
              multiline="true"
              rows={numInputRows}
              id="activity-notes-field"
              className={this.props.classes.textAreaInput}
              defaultValue={this.state.activityNotes}
              onChange={this.handleChange('activityNotes')}
            />
          </FormControl>
          <Button display="block" type="submit" variant="contained" color="primary" className={this.props.classes.submit}>
            Submit
          </Button>
        </div>
      </form>
    );
  }
}

ActivityForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  const { token } = state;
  return {
    token
  };
}

export default connect(mapStateToProps)(withStyles(styles)(ActivityForm));
