import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import axios from '../../services/axios';
import Routes from '../../util/routes';
import { addSnackbarMessageAction } from '../../actions/snackbarActions';


const useStyles = makeStyles(theme => ({
    main: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(6))]: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    passwordResetLink: {
      textDecoration: 'none',
      color: theme.palette.secondary.main,
      fontWeight: 600,
      "&:hover": {
        color: 'red',
      }
    },
  }));

  const RequestPasswordReset = props => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [ requestSuccess, setRequestSuccess ] = useState(false);

    const handleResetPasswordRequest = async e => {
      e.preventDefault();
      const emailAddress = document.getElementById('email').value;
      const payload = { emailAddress };
      try {
        const response = await axios.post(Routes.requestPasswordReset, payload);
        console.log(response);
        if (response.status === 200) {
          dispatch(addSnackbarMessageAction({
            message: 'If an account was found you will receive an email to reset your password.',
            variant: 'success',
          }));
          setRequestSuccess(true);
        }
      } catch (err) {
        dispatch(addSnackbarMessageAction({
          message: 'An error occured while resetting your password.',
          variant: 'error',
        }))
        console.log('Error requesting password reset');
      }
    }

    if (requestSuccess) {
      return <Redirect to={{ pathname: `${Routes.home}`}} />;
    }

    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">Reset Your Password</Typography>
          <form className={classes.form} onSubmit={handleResetPasswordRequest}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input id="email" name="email" autoComplete="email" autoFocus />
            </FormControl>
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              Send password reset email
            </Button>
          </form>
        </Paper>
      </main>
    );
  }

  // const mapDispatchToProps = { addSnackbarMessageAction };

  export default RequestPasswordReset;
