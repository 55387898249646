import { SET_MASQUERADE_ID } from '../actions/types';

const initialState = {
  masqueradeId: -1
};

export function masqueradeIdReducer(state = initialState.masqueradeId, action) {
  switch (action.type) {
    case SET_MASQUERADE_ID:
      const { masqueradeId } = action.payload;
      return masqueradeId;
    default:
      return state;
  }
};
