import React from 'react';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Routes from '../../util/routes';


const ReportLinks = props => {
  return (
    <>
      <List
        subheader={
          <ListSubheader component="div">
            Tools
          </ListSubheader>
        }
      >
        <ListItem button component={Link} to={Routes.essJobsUpload}>
          <ListItemText primary={'Upload Ess Jobs'} />
        </ListItem>
      </List>
    </>
  );
}

export default ReportLinks;
