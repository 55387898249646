import React, { useEffect, useState } from 'react';
import axios from '../../services/axios';
import Typography from '@material-ui/core/Typography';
import { ALL_USER_ID } from '../../util/constants';
import { makeStyles } from '@material-ui/styles';
import Card from './EventCard'
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    main: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up(400 + theme.spacing(6))]: {
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
      },
    },
    cards: {
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap'
    },
    banner: {
      width: '100%',
      padding: 10,
      background: '#c30000',
      marginBottom: 50
    },
    typography: {
      marginBottom: 50
    }
}));

function sortEvents(events){
  let result = []
  events.forEach(e=> {
    if(result[e.tenant_id]){
      result[e.tenant_id].events.push({
        name: e.event_type_name || '/Event Type/',
        date: e.event_date
      })
    }
    else{
      result[e.tenant_id] = {
        tenant: e.tenant_name,
        manager: e.first_name || 'No Manager',
        events: [{
          name: e.event_type_name || '/Event Type/',
          date: e.event_date
        }]
      }
    }
  });
  return result
}

function EvenDashboard(props){
    const classes = useStyles()
    const [events, setEvents] = useState([]);
    const { masqueradeId, token } = props;

    useEffect(() => {
      const fetchEvents = async () => {
        const bearerToken = `Bearer ${token}`;
        const options = {
          headers: {
            Authorization: bearerToken
          }
        };

        let events = [];
        try {
          const baseEndpoint = '/events';
          const endpoint = masqueradeId === ALL_USER_ID ? baseEndpoint : `${baseEndpoint}/byTenant/${masqueradeId}`;
          const response = await axios.get(endpoint, options);
          events = response.data.events;
          console.log(events)
          if (events) {
            setEvents(sortEvents(events));
          }
        } catch (error) {
          events = [];
          setEvents(events);
        }
      };

      fetchEvents();
    }, [token, masqueradeId]);


    return(
        <main className={classes.main}>
            <Typography component="h1" variant="h5" className={classes.typography}>Event Dashboard</Typography>
            {/* <div className={classes.banner}>Recent Updates</div> */}
            <div className={classes.cards}>
              {events.map( e => {
                return <Card events={e.events} tenant={e.tenant} manager={e.manager}/>
              })}
            </div>
        </main>
    )
}

const mapStateToProps = ({ masqueradeId, token }) => {
  return { masqueradeId, token };
}

export default connect(mapStateToProps)(EvenDashboard);
