import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MaterialTable, { MTableToolbar } from 'material-table';
import List from '@material-ui/icons/List';
import { makeStyles } from '@material-ui/styles';
import downloadjs from 'downloadjs';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DialogWrapper from '../../dialogs/DialogWrapper';
import Edi945Lines from './Edi945LinesReport';
import axios from '../../../services/axios';
import icons from '../../../util/icons';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
}));

const Edi945HeadersReport = props => {
  const classes = useStyles();
  const { token } = props;

  let ediStartDate;
  let ediEndDate;

  if (localStorage.getItem('ediEndDate')) {
    ediEndDate = localStorage.getItem('ediEndDate');
  } else {
    ediEndDate = moment().endOf('day').toISOString();
    localStorage.setItem('ediEndDate', ediEndDate);
  }

  if (localStorage.getItem('ediStartDate')) {
    ediStartDate = localStorage.getItem('ediStartDate');
  } else {
    ediStartDate = moment().subtract(7, 'days').startOf('day').toISOString();
    localStorage.setItem('ediStartDate', ediStartDate);
  }

  const dateToday = new Date(ediEndDate);
  const dateOneWeekAgo = new Date(ediStartDate);

  const [ fromDate, setFromDate ] = useState(dateOneWeekAgo);
  const [ toDate, setToDate ] = useState(dateToday);

  const columns = [
    {
      tooltip: 'View Lines',
      render: rowData => {
        return (
          <DialogWrapper
            button={({ handleClickOpen }) => {
              return (
                <IconButton size="small" onClick={handleClickOpen} >
                    <List styles={{ margin: 0, padding: 0, fontSize: '50%' }} />
                </IconButton>
              );
            }}
            dialogTitle={`Customer PO # ${rowData.customer_po_number}`}
            content={<Edi945Lines jtTransactionId={rowData.jt_transaction_id} />}
            maxWidth="xl"
          />
        );
      },
    },
    { title: "JT Transaction Id", field: "jt_transaction_id" },
    { title: "Shipment #", field: "shipment_number" },
    { title: "Depositor Order #", field: "depositor_order_number" },
    { title: "Customer", field: "customer" },
    { title: "Customer PO #", field: "customer_po_number" },
    { title: "Bill of Lading", field: "bill_of_lading" },
    { title: "Master Bill of Lading", field: "master_bill_of_lading" },
    { title: "EDI Location Code", field: "edi_location_code" },
    // {
    //   title: "Raw EDI",
    //   field: "raw_edi",
    //   export: false,
    //   render: rowData => {
    //     return (
    //       <DialogWrapper
    //         dialogActionButton={
    //           <Button
    //             size="small"
    //             variant="contained"
    //             color="primary"
    //             onClick={() => {
    //               return downloadjs(rowData.raw_edi, "RawEdi.edi", "text/plain");
    //             }}
    //           >
    //             Download
    //           </Button>
    //         }
    //         button={({ handleClickOpen }) => {
    //           return (
    //             <Button
    //               size="small"
    //               variant="contained"
    //               color="primary"
    //               onClick={handleClickOpen}
    //             >
    //               View
    //             </Button>
    //           );
    //         }}
    //         dialogTitle='Raw EDI File'
    //         content={rowData.raw_edi}
    //       />
    //     );
    //   }
    // },
    { title: "# of Lines", field: "num_lines" },
    { title: "Process Status", field: "process_status" },
    { title: "Process Message", field: "process_message" },
    {
      title: "Creation Date",
      field: "created_at",
      defaultSort: "desc",
      render: rowData => {
        if (rowData.created_at !== null) {
          return moment(rowData.created_at).format('MM/DD/YYYY h:mm:ss a');
        }
      }
    },
  ];

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetch945Transactions = async () => {
      const endpoint = '/reports/945-transactions';
      const bearerToken = `Bearer ${token}`;
      const options = {
        headers: {
          Authorization: bearerToken,
        },
        params: {
          fromDate,
          toDate,
        },
      };

      let transactionData = [];
      try {
        const response = await axios.get(endpoint, options);
        transactionData = response.data.transactions;
        setData(transactionData);
      } catch (error) {
        transactionData = [];
        console.log(error)
        setData(transactionData);
      }
    };

    fetch945Transactions();
  }, [token, fromDate, toDate]);




  const renderDateFilter = props => {
    return (
      <>
        <Grid container justify="flex-end">
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="to-date-picker-dialog"
                label="From date"
                format="MM/dd/yyyy"
                value={fromDate}
                onChange={date => {
                  localStorage.setItem('ediStartDate', date.toISOString());
                  setFromDate(date);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change from date',
                }}
              />
              <KeyboardDatePicker
                margin="normal"
                id="from-date-picker-dialog"
                label="To date"
                format="MM/dd/yyyy"
                value={toDate}
                onChange={date => {
                  localStorage.setItem('ediEndDate', date.toISOString());
                  setToDate(date);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change to date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item>
            <MTableToolbar {...props}/>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <main className={classes.main}>
      <MaterialTable
        columns={columns}
        data={data}
        title="EDI 945 Transactions"
        options={{
          filtering: true,
          search: false,
          columnsButton: true,
          exportButton: true,
          exportAllData: true,
          padding: 'dense',
          pageSize: 25,
          pageSizeOptions: [25, 50, 100],
          emptyRowsWhenPaging: false,
          showTitle: false,
        }}
        icons={icons}
        components={{
          Toolbar: renderDateFilter
        }}
      />
    </main>
  );
};

const mapStateToProps = (state) => {
  const { token } = state;

  return {
    token
  };
}

export default connect(mapStateToProps)(Edi945HeadersReport);
