import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import myAxios from '../../services/axios';
import axios from 'axios';
import { addSnackbarMessageAction } from '../../actions/snackbarActions';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: '60%',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(2),
    display: 'block',
    alignSelf: 'center'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '50%'
  },
  dense: {
    marginTop: 19
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '50%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
  input: {
    width: '50%'
  }
});

class EventForm extends React.Component {
  constructor(props) {
    super(props);
    const defaultDate = new Date().toISOString().split('T')[0];
    const defaultStatus = 'Upcoming';

    this.statuses = [
      {
        value: 'Upcoming',
        label: 'Upcoming'
      },
      {
        value: 'Closed',
        label: 'Closed'
      }
    ];

    this.state = {
      eventId: this.props.eventId ? this.props.eventId : null,
      instanceId: this.props.instanceId ? this.props.instanceId : null,
      tenantId: this.props.tenantId,
      eventDate: this.props.eventDate ? new Date(this.props.eventDate).toISOString().split('T')[0] : defaultDate,
      releaseVersion: this.props.releaseVersion ? this.props.releaseVersion : '',
      eventStatus: this.props.eventStatus ? this.props.eventStatus : defaultStatus,
      eventDetails: this.props.eventDetails ? this.props.eventDetails : '',
      eventType: this.props.eventType ? this.props.eventType: '' ,
      eventAssignedUserId: this.props.eventAssignedUserId ? this.props.eventAssignedUserId: '',
      lookups: [],
      assignableUsers: [],
      reload: this.props.reload ? this.props.reload : Math.random(),
    };

    this.CancelToken = axios.CancelToken;
    this.source = this.CancelToken.source();
  }

  componentDidMount = () => {
    this.fetchLookups();
    this.fetchAssignableUsers();
  };

  componentWillUnmount() {
    this.source.cancel("Request cancelled by user");
  }

  fetchLookups = async () => {
    const endpoint = `/lookups/event`;
    const { token } = this.props;
    const bearerToken = `Bearer ${token}`;
    const options = {
      headers: {
        Authorization: bearerToken
      },
      cancelToken: this.source.token,
    };

    myAxios
      .get(endpoint, options)
      .then( ({ data }) => {
        if (data && data.lookups) {
          const lookups = data.lookups;
          this.setState({ lookups });
        }
      })
      .catch(err => {
        console.log('An error occured while retrieving event');
        console.log(err);
      });
  };

  fetchAssignableUsers = async () => {
    const endpoint = `/users/assignable`;
    const { token } = this.props;
    const bearerToken = `Bearer ${token}`;
    const options = {
      headers: {
        Authorization: bearerToken
      },
      cancelToken: this.source.token,
    };

    myAxios
      .get(endpoint, options)
      .then(({ data }) => {
        if (data && data.users) {
          const assignableUsers = data.users;
          this.setState({ assignableUsers });
        }
      })
      .catch(err => {
        console.log('Error retrieving assignable users');
      });
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const event = {
      eventId: this.state.eventId,
      instanceId: this.state.instanceId,
      tenantId: this.state.tenantId,
      eventDate: this.state.eventDate,
      releaseVersion: this.state.releaseVersion,
      eventStatus: this.state.eventStatus,
      eventDetails: this.state.eventDetails,
      eventType: this.state.eventType,
      eventAssignedUserId: this.state.eventAssignedUserId,
    };

    let message;
    let variant

    try {
      const success = await this.props.onSubmit(event);
      // need to find a better way to handle this
      if (success) {
        message = this.state.eventId ? 'Save successful' : 'Create successful';
        variant = 'success';

        if ( this.props.handleClose) {
          this.props.handleClose();
        }
      } else {
        message = this.state.eventId ? 'Save failed' : 'Create failed';
        variant = 'error';
      }
      this.props.addSnackbarMessageAction({ message, variant });
    } catch (err) {
        message = this.state.eventId ? 'Save failed' : 'Create failed';
        variant = 'error';
      this.props.addSnackbarMessageAction({ message, variant });
    }
  };

  mapAssignableUsers = users => {
    return users.map((user, idx) => {
      const first_name = user.first_name ? user.first_name : '';
      const last_name = user.last_name ? user.last_name : '';
      const full_name = `${first_name} ${last_name}`;
      const tenant_name = `${user.tenant_name}`;
      const menu_entry = `${tenant_name} - ${full_name}`;
      return <MenuItem key={idx} value={user.id}>{menu_entry}</MenuItem>;
    });
  };

  mapEventTypes = () => {
    return (
      this.statuses.map((status, idx) => (
        <option key={idx} value={status.value}>
          {status.label}
        </option>
      ))
    );
  }

  render() {
    return (
      <form className={this.props.classes.form} onSubmit={this.handleSubmit}>
        <Grid container direction="column">
          <Grid item>
            <TextField
              required
              id="event-date-field"
              label='Event Date'
              type="date"
              className={this.props.classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              value={this.state.eventDate}
              onChange={this.handleChange('eventDate')}
            />
          </Grid>
          <Grid item>
            <TextField
              id="event-release-version-field"
              label='Release Version'
              className={this.props.classes.textField}
              margin="normal"
              value={this.state.releaseVersion}
              onChange={this.handleChange('releaseVersion')}
            />
          </Grid>
          <Grid item>
            <TextField
              id="event-status-field"
              select
              label="Event Status"
              className={this.props.classes.textField}
              value={this.state.eventStatus}
              onChange={this.handleChange('eventStatus')}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: this.props.classes.menu,
                },
              }}
              margin="normal"
            >
              { this.mapEventTypes() }
            </TextField>
          </Grid>
          <Grid item>
            <TextField
              id="event-type-field"
              select
              label="Event Type"
              className={this.props.classes.textField}
              value={this.state.eventType}
              onChange={this.handleChange('eventType')}
              SelectProps={{
                native: true,
                MenuProps: {
                  className: this.props.classes.menu,
                },
              }}
              margin="normal"
            >
              {
                this.state.lookups.map((lookup, idx) => (
                  <option key={idx} value={lookup.id}>
                    {lookup.name}
                  </option>
                ))
              }
            </TextField>
          </Grid>
          <Grid item>
            <FormControl className={this.props.classes.formControl}>
              <InputLabel htmlFor="event-assigned-user-field">Assigned User</InputLabel>
              <Select
                autoWidth
                id="event-assigned-user-field"
                value={this.state.eventAssignedUserId}
                onChange={this.handleChange('eventAssignedUserId')}
              >
                { this.mapAssignableUsers() }
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              id="event-details-field"
              label='Event Details'
              className={this.props.classes.textField}
              margin="normal"
              value={this.state.eventDetails}
              onChange={this.handleChange('eventDetails')}
            />
          </Grid>
          <Grid item>
            <Button display="block" type="submit" variant="contained" color="secondary" className={this.props.classes.submit}>
              { this.state.eventId === null ? 'Submit' : 'Save Changes' }
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

EventForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  const { token } = state;
  return {
    token
  };
}

const mapDispatchToProps = { addSnackbarMessageAction };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EventForm));
