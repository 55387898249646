import React from 'react';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import FormDialogWrapper from '../dialogs/FormDialogWrapper';
import TenantForm from '../forms/TenantForm';

function TenantsIndexTableRow(props) {
  const { onSubmit, row } = props;
  return (
    <>
      <TableRow>
        <TableCell>
          <FormDialogWrapper
            button={({ buttonText, handleClickOpen }) => {
              return (
                <Button onClick={handleClickOpen}>
                  {buttonText}
                </Button>
              )
            }}
            buttonText={row.tenant_name}
            dialogTitle={`Editing ${row.tenant_name}`}
            content={({handleClickOpen, handleClose}) => {
              return (
                <TenantForm
                  tenantId={row.id}
                  tenantName={row.tenant_name}
                  tenantAdminEmail={row.tenant_admin}
                  tenantPlanType={row.plan_type}
                  tenantMailbox={row.mailbox}
                  onSubmit={onSubmit}
                  handleClickOpen={handleClickOpen}
                  handleClose={handleClose}
                />
              )
            }}
          />
        </TableCell>
        <TableCell>{row.first_name} {row.last_name}</TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>{row.plan_type_name}</TableCell>
        <TableCell>{row.mailbox}</TableCell>
        <TableCell>{row.num_users}</TableCell>
      </TableRow>
    </>
  );
}

export default TenantsIndexTableRow;
