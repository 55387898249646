import React, { useCallback, useState, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import {useDropzone} from 'react-dropzone';
import { Button, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InstanceDropdown from '../instances/InstancesDropdown';
import axios from '../../services/axios';
import Routes from '../../util/routes';

const useStyles = makeStyles(theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    maxWidth: '1200px',
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(3),
    }
  },
  paper: {
    padding: theme.spacing(3),
  },
}));

const EssJobUploadPage = props => {
  const { token, masqueradeId } = props;
  const [ file, setFile ] = useState({});
  const [fileId, setFileId ] = useState(null);
  const [instance, setInstance ] = useState(-1);
  const classes = useStyles();

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        setFile(file);
      }

      reader.readAsDataURL(file);
    });
  }, []);

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({ onDrop });

  const style = useMemo(() => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  }), []);

  const handleSubmit = async e => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('essJobFile', file);
    formData.append('tenantId', masqueradeId);
    formData.append('instanceId', instance);

    const bearerToken = `Bearer ${token}`;
    const endpoint = Routes.essJobsUploadEndpoint;
    const options = {
      headers: {
        Authorization: bearerToken,
        'content-type': 'multipart/form-data',
      }
    };

    const response = await axios.post(endpoint, formData, options);
    console.log(response);
    const { status } = response;
    const { fileId } = response.data;
    if (status === 200) {
      console.log(`redirecting to show page for ${fileId}`);
      setFileId(fileId);
    }
  }

  const uploadedFiles = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  if (fileId) {
    return <Redirect to={{ pathname: `${Routes.essJobsUpload}/${fileId}`}} />;
  }

  return (
    <section className={classes.main}>
      <Paper className={classes.paper}>
        <InstanceDropdown instance={instance} setInstance={setInstance} />
        <div {...getRootProps({style})}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
        <aside>
          <h4>Files</h4>
          <ul>{uploadedFiles}</ul>
        </aside>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Paper>
    </section>
  );
}

export default EssJobUploadPage;
