import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { setMasqueradeIdAction } from '../../actions';
import axios from '../../services/axios';

const useStyles = makeStyles(theme => ({
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: '60%',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    color: 'white',
  },
  submit: {
    marginTop: theme.spacing(4)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40%'
  },
  dense: {
    marginTop: 19
  },
  outlinedInput: {
    color: 'white',
  },
  selectEmpty: {
    color: 'white'
  }
}));

function MasqueradeDropdown(props) {
  const { masqueradeId, token, setMasqueradeIdAction } = props;

  const [tenants, setTenants] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    const fetchTenants = async () => {
      try {
        const bearerToken = `Bearer ${token}`;
        const options = {
          headers: {
            Authorization: bearerToken
          }
        };
        const response = await axios.get('/tenants', options);
        const tenants = response.data.tenants;
        setTenants(tenants);
      } catch {
        console.log('Error fetching tenants');
      }
    }

    fetchTenants();
  }, [token]);

  const handleTenantChange = (e) => {
    const id = parseInt(e.target.value);
    setMasqueradeIdAction(id);
  }

  return (
    <FormControl required className={classes.formControl}>
      <InputLabel shrink htmlFor="masqueradeId" className={classes.formControl}>
        Proxy Tenant
      </InputLabel>
      <Select
        value={masqueradeId}
        onChange={ e => handleTenantChange(e) }
        input={<OutlinedInput name="masqueradeId" id="masqueradeId" className={classes.outlinedInput} />}
        displayEmpty
        name="masqueradeId"
        className={classes.selectEmpty}
      >
        <MenuItem value="-1">
          <em>None</em>
        </MenuItem>
        {
          tenants.map(({ id, tenant_name }) => (
          <MenuItem key={id} value={id}>
            <em>{tenant_name}</em>
          </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
}

const mapStateToProps = state => {
  return {
    token: state.token,
    masqueradeId: state.masqueradeId
  };
}

const mapDispatchToProps = {
  setMasqueradeIdAction
};

export default connect(mapStateToProps, mapDispatchToProps)(MasqueradeDropdown);
