import { SNACKBAR_CLEAR, SNACKBAR_SUCCESS } from '../actions/types';

const initialState = {
    successSnackbarMessage: { message: '', variant: ''},
    successSnackbarOpen: false,
};

export function uiSnackbarMessageReducer(state = initialState.successSnackbarMessage, action) {
  let message = {};

  switch (action.type) {
    case SNACKBAR_CLEAR:
      message = {};
      return message;
    case SNACKBAR_SUCCESS:
      message = action.payload;
      return message;
    default:
      return state;
  }
};

export function uiSnackbarStateReducer(state = initialState.successSnackbarOpen, action) {
  let successSnackbarOpen;

  switch (action.type) {
    case SNACKBAR_CLEAR:
      successSnackbarOpen = false;
      return successSnackbarOpen;
    case SNACKBAR_SUCCESS:
      successSnackbarOpen = true;
      return successSnackbarOpen;
    default:
      return state;
  }
};
