import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { formatDate } from '../../util/helpers';


function ActivityIndexTableRow(props) {
  const { activity } = props;

  return (
    <TableRow key={activity.id}>
      <TableCell>{activity.type}</TableCell>
      <TableCell>{formatDate(activity.created_at)}</TableCell>
      <TableCell>{activity.notes}</TableCell>
    </TableRow>
  );
}

export default ActivityIndexTableRow;
