import React from 'react';
import Typography from '@material-ui/core/Typography';
import TenantsIndexTable from './TenantsIndexTable';


function TenantIndexPage(props) {
  return (
    <>
      <Typography component="h1" variant="h5">Tenant Overview</Typography>
      <TenantsIndexTable />
    </>
  );
}

export default TenantIndexPage;
