import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import axios from '../../services/axios';
import InstanceCard from '../instances/InstanceCard';


function TenantShow(props) {
  const { masqueradeId, token} = props;

  const [instances, setInstances] = useState([]);

  useEffect(() => {
    const fetchInstances = async () => {
      let instances = [];
      const bearerToken = `Bearer ${token}` ;
      const options = {
        headers: {
          Authorization: bearerToken
        }
      };

      try {
        const endpoint = `/instances/byTenant/${masqueradeId}`;
        const response = await axios.get(endpoint, options);
        instances = response.data.instances;
        setInstances(instances);
      } catch(err) {
        instances = [];
        setInstances(instances);
      }
    }

    fetchInstances();
  }, [token, masqueradeId]);

  const renderInstanceCards = instances => {
    return instances.map(instance => {
      return (
        <Grid item>
          <InstanceCard key={instance.id} instance={instance} />
        </Grid>
      );
    });
  }

  return (
    <>
      <Typography component="h1" variant="h5">
        Instances
      </Typography>
      <Grid container justify="center" spacing={2}>
        {renderInstanceCards(instances)}
      </Grid>
    </>
  );
}

const mapStateToProps = state => {
  return {
    token: state.token,
    masqueradeId: state.masqueradeId
  }
}

export default connect(mapStateToProps)(TenantShow);
