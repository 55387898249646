import React from 'react';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Routes from '../../util/routes';


const AdminLinks = props => {
  return (
    <>
      <List
        subheader={
          <ListSubheader component="div">
            Admin
          </ListSubheader>
        }
      >
        <ListItem button component={Link} to={Routes.eventDash}>
          <ListItemText primary={'Event Dashboard'} />
        </ListItem>
        <ListItem button component={Link} to={Routes.admin}>
          <ListItemText primary={'Admin Dashboard'} />
        </ListItem>
        <ListItem button component={Link} to={Routes.essJobIndex}>
          <ListItemText primary={'ESS Jobs'} />
        </ListItem>
        <ListItem button component={Link} to={Routes.eventIndex}>
          <ListItemText primary={'Event Overview'} />
        </ListItem>
        <ListItem button component={Link} to={Routes.instanceIndex}>
          <ListItemText primary={'Instance Overview'} />
        </ListItem>
        <ListItem button component={Link} to={Routes.tenantIndex}>
          <ListItemText primary={'Tenant Overview'} />
        </ListItem>
        <ListItem button component={Link} to={Routes.tenantIndex}>
          <ListItemText primary={'Lookup Management'} />
        </ListItem>
      </List>
    </>
  );
}

export default AdminLinks;
