import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import axios from '../../services/axios';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: '60%',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(4),
    display: 'block',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40%'
  },
  dense: {
    marginTop: 19
  }
});

class InstanceForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tenants: [],
      instanceId: this.props.instanceId ? this.props.instanceId : null,
      tenantId: this.props.tenantId ? this.props.tenantId : -1,
      instanceType: this.props.instanceType ? this.props.instanceType : '',
      domain: this.props.domain ? this.props.domain : '',
      environment: this.props.environment ? this.props.environment : '',
      dataCenter: this.props.dataCenter ? this.props.dataCenter : '',
      version: this.props.version ? this.props.version : '',
      maintenanceSchedule: this.props.maintenanceSchedule ? this.props.maintenanceSchedule : '',
      url: this.props.url ? this.props.url : ''
    };
  }

  componentDidMount = () => {
    this.fetchTenants();
  };

  fetchTenants = async () => {
    try {
      const { token } = this.props;
      const bearerToken = `Bearer ${token}`;
      const options = {
        headers: {
          Authorization: bearerToken
        }
      };
      const response = await axios.get('/tenants', options);
      const tenants = response.data.tenants;
      this.setState({ tenants });
    } catch {
      console.log('Error fetching tenant list');
    }
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const payload = {
      instanceId: this.state.instanceId,
      dataCenter: this.state.dataCenter,
      domain: this.state.domain,
      environment: this.state.environment,
      instanceType: this.state.instanceType,
      maintenanceSchedule: this.state.maintenanceSchedule,
      tenantId: this.state.tenantId,
      url: this.state.url,
      version: this.state.version
    };

    const exit = await this.props.onSubmit(payload);

    if(exit) {
      this.props.handleClose();
    }
  };

  renderTenants = tenants => {
    return tenants.map(({ id, tenant_name }) => (
      <MenuItem key={id} value={id}>
        <em>{tenant_name}</em>
      </MenuItem>
    ));
  };

  render() {
    return (
      <form className={this.props.classes.form} onSubmit={this.handleSubmit}>
        <FormControl required fullWidth className={this.props.classes.formControl}>
          <InputLabel shrink htmlFor="tenantId">
            Tenant
          </InputLabel>
          <Select
            value={this.state.tenantId}
            onChange={this.handleChange('tenantId')}
            input={<Input required name="tenantId" id="tenantId" className={this.props.classes.input} />}
            displayEmpty
            name="tenantId"
            className={this.props.classes.selectEmpty}
          >
            <MenuItem value="-1">
              <em>None</em>
            </MenuItem>
            {this.renderTenants(this.state.tenants)}
          </Select>
        </FormControl>
        <TextField
          required
          id="instance-type-field"
          label="Instance Type"
          className={this.props.classes.textField}
          margin="normal"
          value={this.state.instanceType}
          onChange={this.handleChange('instanceType')}
        />
        <TextField
          required
          id="environment-field"
          label="Environment"
          className={this.props.classes.textField}
          margin="normal"
          value={this.state.environment}
          onChange={this.handleChange('environment')}
        />
        <TextField
          required
          id="data-center-field"
          label="Data Center"
          className={this.props.classes.textField}
          margin="normal"
          value={this.state.dataCenter}
          onChange={this.handleChange('dataCenter')}
        />
        <TextField
          required
          id="domain-field"
          label="Domain"
          className={this.props.classes.textField}
          margin="normal"
          value={this.state.domain}
          onChange={this.handleChange('domain')}
        />
        <TextField
          required
          id="version-field"
          label="Version"
          className={this.props.classes.textField}
          margin="normal"
          value={this.state.version}
          onChange={this.handleChange('version')}
        />
        <TextField
          required
          id="maintenance-schedule-field"
          label="Maintenance Schedule"
          className={this.props.classes.textField}
          margin="normal"
          value={this.state.maintenanceSchedule}
          onChange={this.handleChange('maintenanceSchedule')}
        />
        <TextField
          required
          id="url-field"
          label="URL"
          className={this.props.classes.textField}
          margin="normal"
          value={this.state.url}
          onChange={this.handleChange('url')}
        />
        <Button type="submit" variant="contained" color="secondary" className={this.props.classes.submit}>
          Submit
        </Button>
      </form>
    );
  }
}

InstanceForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  const { token } = state;
  return {
    token
  };
}

export default connect(mapStateToProps)(withStyles(styles)(InstanceForm));
