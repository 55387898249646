import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import myAxios from '../../services/axios';
import axios from 'axios';
import { addSnackbarMessageAction } from '../../actions/snackbarActions';

const useStyles = makeStyles(theme => ({
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: '60%',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(2),
    display: 'block',
    alignSelf: 'center'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '50%'
  },
  dense: {
    marginTop: 19
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '50%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
  input: {
    width: '50%'
  }
}));

const mapEventStatuses = statuses => {
  return statuses.map((status) => (
    <option key={status.id} value={status.id}>
      {status.name}
    </option>
  ));
};

const mapEventTypes = eventTypes => {
  return eventTypes.map((eventType, idx) =>(
    <option key={eventType.id} value={eventType.id}>
      {eventType.name}
    </option>
  ));
};

const mapAssignableUsers = users => {
  return users.map((user, idx) => {
    const first_name = user.first_name ? user.first_name : '';
    const last_name = user.last_name ? user.last_name : '';
    const full_name = `${first_name} ${last_name}`;
    const tenant_name = `${user.tenant_name}`;
    const menu_entry = `${tenant_name} - ${full_name}`;
    return <option key={idx} value={user.id}>{menu_entry}</option>;
  });
};


const EditEventForm = props => {
  const classes = useStyles();
  const { assignableUsers, event, eventStatuses, eventTypes } = props;

  const token = useSelector(state => state.token);
  const dispatch = useDispatch();

  const [eventId, setEventId] = useState(null);
  useEffect(() => setEventId(event.id), [event]);

  const [instanceId, setInstanceId] = useState(null);
  useEffect(() => setInstanceId(event.instance_id), [event]);

  const [eventDate, setEventDate] = useState(null);
  useEffect(() => setEventDate(event.event_date), [event]);

  const [tenantId, setTenantId] = useState(null);
  useEffect(() => setTenantId(event.tenant_id), [event]);

  const [releaseVersion, setReleaseVersion] = useState('');
  useEffect(() => setReleaseVersion(event.release_version), [event]);

  const [eventStatus, setEventStatus] = useState('');
  useEffect(() => setEventStatus(event.event_status), [event]);

  const [eventType, setEventType] = useState('');
  useEffect(() => setEventType(event.event_type_name), [event]);

  const [eventDetails, setEventDetails] = useState('');
  useEffect(() => setEventDetails(event.event_details), [event]);

  const [eventAssignedUserId, setEventAssignedUserId] = useState(null);
  useEffect(() => setEventAssignedUserId(event.assigned_user_id), [event]);

  const handleChange = name => event => {
    const { value } = event.target;
    switch (name) {
      case 'instanceId':
        setInstanceId(value);
        return;
      case 'eventDate':
        setEventDate(value);
        return;
      case 'releaseVersion':
        setReleaseVersion(value);
        return;
      case 'eventStatus':
        setEventStatus(value);
        return;
      case 'eventType':
        setEventType(value);
        return;
      case 'eventAssignedUserId':
        setEventAssignedUserId(value);
        return
      default:
        return;
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    let response;

    try {
      const bearerToken = `Bearer ${token}`;
      const endpoint = `/events/${eventId}`;
      const config = {
        headers: {
          Authorization: bearerToken
        }
      };

      const payload = {
        eventId,
        instanceId,
        tenantId,
        eventDate,
        releaseVersion,
        eventStatus,
        eventDetails,
        eventType,
        eventAssignedUserId,
      };

      response = await myAxios.put(endpoint, payload, config);

      if (response.status === 200) {
        dispatch(addSnackbarMessageAction({
          message: 'Changes have been saved',
          variant: 'success',
        }));
      }
    } catch (err) {
      dispatch(addSnackbarMessageAction({
        message: 'Changes were not saved',
        variant: 'error',
      }));
    }
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Grid container direction="column">

        <Grid item>
          <TextField
            required
            id="event-date-field"
            label='Event Date'
            type="date"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            value={'2020-01-01'}
            onChange={handleChange('eventDate')}
          />
        </Grid>

        <Grid item>
          <TextField
            id="event-release-version-field"
            label='Release Version'
            className={classes.textField}
            margin="normal"
            value={releaseVersion}
            onChange={handleChange('releaseVersion')}
          />
        </Grid>

        <Grid item>
          <TextField
            id="event-status-field"
            select
            label="Event Status"
            className={classes.textField}
            value={eventStatus}
            onChange={handleChange('eventStatus')}
            SelectProps={{
              native: true,
              MenuProps: {
                className: classes.menu,
              },
            }}
            margin="normal"
          >
            { mapEventStatuses(eventStatuses) }
          </TextField>
        </Grid>

        <Grid item>
          <TextField
            id="event-type-field"
            select
            label="Event Type"
            className={classes.textField}
            value={eventType}
            onChange={handleChange('eventType')}
            SelectProps={{
              native: true,
              MenuProps: {
                className: classes.menu,
              },
            }}
            margin="normal"
          >
            { mapEventTypes(eventTypes) }
          </TextField>
        </Grid>

        <Grid item>
          <TextField
            id="event-assigned-user-field"
            select
            label="Assigned User"
            className={classes.textField}
            value={eventType}
            onChange={handleChange('eventAssignedUserId')}
            SelectProps={{
              native: true,
              MenuProps: {
                className: classes.menu,
              },
            }}
            margin="normal"
          >
            { mapAssignableUsers(assignableUsers) }
          </TextField>
        </Grid>

        <Grid item>
          <Button
            display="block"
            type="submit"
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            Save Changes
          </Button>
        </Grid>

      </Grid>
    </form>
  );
};

export default EditEventForm;
