import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import Routes from '../../util/routes';
import axios from '../../services/axios';
import icons from '../../util/icons';


const useStyles = makeStyles(theme => ({
  root: {
    width: '90%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  button: {
    margin: theme.spacing(1),
  },
  stale: {
    backgroundColor: 'lightcoral',
  }
}));


const EssJobUploadShow = props => {
  const classes = useStyles();
  const { token } = props;
  const [ lines, setLines ] = useState([]);
  const { fileId } = props.match.params;

  useEffect(() => {
    const fetchEssJobFileLines = async () => {
      const bearerToken = `Bearer ${token}`;
      const options = {
        headers: {
          Authorization: bearerToken
        }
      };

      try {
        const endpoint = `/ess-jobs/lines/${fileId}`;
        const response = await axios.get(endpoint, options);
        let { lines } = response.data;
        if (lines) {
          setLines(lines);
        }
      } catch (error) {
        let lines = [];
        setLines(lines);
      }
    };

    fetchEssJobFileLines();
  }, [token, fileId]);

  const handleSubmitJobClick = async (rowData) => {
    try {
      const { id } = rowData;
      console.log(`Submitting job for lineId: ${id}`);

      const endpoint = `${Routes.essJobsLines}/${id}`;
      const bearerToken = `Bearer ${token}`;
      const payload = { lineId: id };
      const options = {
        headers: {
          Authorization: bearerToken,
        }
      };

      const response = await axios.post(endpoint, payload, options);
      const { status } = response;

      if (status === 200) {
        window.location.reload(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmitAllJobsClick = async (fileId) => {
    try {
      console.log(`Submitting all jobs for fileid: ${fileId}`);

      const endpoint = `${Routes.essJobFile}/${fileId}/submit`;
      const bearerToken = `Bearer ${token}`;
      const payload = {};
      const options = {
        headers: {
          Authorization: bearerToken,
        }
      };

      const response = await axios.post(endpoint, payload, options);
      const { status } = response;

      if (status === 200) {
        window.location.reload(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleRefreshJobStatusClick = async (rowData) => {
    try {
      const { id } = rowData;
      console.log(`Submitting status refresh job for lineId: ${id}`);

      const endpoint = `${Routes.essJobsLines}/${id}/refresh`;
      const bearerToken = `Bearer ${token}`;
      const payload = { lineId: id };
      const options = {
        headers: {
          Authorization: bearerToken,
        }
      };

      const response = await axios.post(endpoint, payload, options);
      const { status } = response;

      if (status === 200) {
        window.location.reload(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleRefreshAllJobStatusesClick = async (fileId) => {
    try {
      console.log(`Submitting all jobs for fileid: ${fileId}`);

      const endpoint = `${Routes.essJobFile}/${fileId}/status`;
      const bearerToken = `Bearer ${token}`;
      const payload = {};
      const options = {
        headers: {
          Authorization: bearerToken,
        }
      };

      const response = await axios.post(endpoint, payload, options);
      const { status } = response;

      if (status === 200) {
        window.location.reload(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      title: 'Actions',
      render: rowData => {
        return (
          <Box display="flex">
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginRight: '2px' }}
              onClick={() => handleSubmitJobClick(rowData)}
            >
              Submit
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handleRefreshJobStatusClick(rowData)}
            >
              Refresh
            </Button>
          </Box>
        );
      }
    },
    { title: 'Request Id', field: 'request_id' },
    { title: 'ESS Job', field: 'ess_job' },
    { title: 'Status', field: 'job_status' },
    { title: 'Parameter 1', field: 'parameter_1' },
    { title: 'Parameter 2', field: 'parameter_2' },
    { title: 'Parameter 3', field: 'parameter_3' },
    { title: 'Parameter 4', field: 'parameter_4' },
    { title: 'Parameter 5', field: 'parameter_5' },
    { title: 'Parameter 6', field: 'parameter_6' },
    // { title: 'Parameter 7', field: 'parameter_7' },
    // { title: 'Parameter 8', field: 'parameter_8' },
    // { title: 'Parameter 9', field: 'parameter_9' },
    // { title: 'Parameter 10', field: 'parameter_10' },
  ];

  const processButtons = props => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <MTableToolbar {...props} />
        <Box display="flex">
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginTop: '5px', marginRight: '4px', height: '40px' }}
              onClick={() => handleSubmitAllJobsClick(fileId)}
            >
              Submit All
            </Button>

            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginTop: '5px', marginRight: '4px', height: '40px' }}
              onClick={() => handleRefreshAllJobStatusesClick(fileId)}
            >
              Refresh All
            </Button>
          </Box>
      </div>
    );
  }

  return (
    <main className={classes.main}>
      <MaterialTable
        columns={columns}
        data={lines}
        title='Ess Job Requests'
        options={{
          filtering: true,
          grouping: false,
          search: false,
          padding: 'dense',
          pageSize: 5,
          pageSizeOptions: [5, 10, 25, 50, 100],
          emptyRowsWhenPaging: false,
          maxBodyHeight: '10%',
        }}
        icons={icons}
        components={{
          Toolbar: processButtons,
        }}
      />
    </main>
  );
}

const mapStateToProps = ({ masqueradeId, token }) => {
  return { masqueradeId, token };
}

export default connect(mapStateToProps)(EssJobUploadShow);
