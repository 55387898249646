const accessRules = {
  admin: [
    'view:admin',
    'view:events',
    'view:home',
    'view:proxy',
    'view:reports',
    'view:tenants',
    'view:ess-job-upload',
  ],
  user: [
    'view:home',
    'view:reports',
  ],
  essUpload: [
    'view:ess-job-upload',
  ],
};


export default accessRules;
