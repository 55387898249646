import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { setMasqueradeIdAction } from '../../actions';
import axios from '../../services/axios';

const useStyles = makeStyles(theme => ({
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: '60%',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  submit: {
    marginTop: theme.spacing(4)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40%'
  },
  dense: {
    marginTop: 19
  },
}));

function InstancesDropdown(props) {
  const { instance, masqueradeId, setInstance, token } = props;

  const [instances, setInstances ] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    const fetchTenantInstances = async () => {
      const bearerToken = `Bearer ${token}`;
      const options = {
        headers: {
          Authorization: bearerToken
        }
      };

      try {
        const endpoint = `/tenants/instances/${masqueradeId}`;
        const response = await axios.get(endpoint, options);
        let { instances } = response.data;
        if (instances) {
          setInstances(instances);
        }
      } catch (error) {
        let instances = [];
        setInstances(instances);
      }
    };

    fetchTenantInstances();
  }, [token, masqueradeId]);

  const handleInstanceChange = e => {
    const newInstance = e.target.value;
    setInstance(newInstance);
  }

  return (
    <FormControl required className={classes.formControl}>
      <InputLabel shrink htmlFor="instance" className={classes.formControl}>
        Instances
      </InputLabel>
      <Select
        value={instance}
        onChange={ e => handleInstanceChange(e) }
        input={<OutlinedInput name="instance" id="instance" className={classes.outlinedInput} />}
        displayEmpty
        name="instance"
        className={classes.selectEmpty}
      >
        <MenuItem value="-1">
          <em>None</em>
        </MenuItem>
        {
          instances.map(({ environment, id, instance_type, tenant_name }) => (
          <MenuItem key={id} value={id}>
            <em>{`${tenant_name} - ${environment} ${instance_type}`}</em>
          </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
}

const mapStateToProps = state => {
  return {
    token: state.token,
    masqueradeId: state.masqueradeId
  };
}

const mapDispatchToProps = {
  setMasqueradeIdAction
};

export default connect(mapStateToProps, mapDispatchToProps)(InstancesDropdown);
