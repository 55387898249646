import React, { useEffect, useState } from 'react';
import axios from '../../services/axios';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import EssJobIndexTableRow from './EssJobIndexTableRow';
import { ALL_USER_ID } from '../../util/constants';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  },
  toolbar: {
    paddingRight: theme.spacing(1)
  },
  spacer: {
    flex: '1 1 100%'
  },
  fab: {
    margin: theme.spacing(2)
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  }
}));

function EssJobIndexTable(props) {
  const classes = useStyles();
  const { masqueradeId, token } = props;
  const [ essJobs, setEssJobs ] = useState([]);

  useEffect(() => {
    const fetchTenantData = async () => {
      let essJobs = [];
      try {
        const bearerToken = `Bearer ${token}` ;
        const baseUrl = '/ess-jobs';
        const endpoint = masqueradeId === ALL_USER_ID ? baseUrl : `${baseUrl}/${masqueradeId}`;
        const options = {
          headers: {
            Authorization: bearerToken
          }
        };
        const response = await axios.get(endpoint, options);
        essJobs = response.data.essJobs;
        setEssJobs(essJobs);
      } catch (error) {
        essJobs = [];
        setEssJobs([]);
      }
    }

    fetchTenantData();
  }, [token, masqueradeId]);

  const handleClick = essJobDefinition => async event => {
    event.preventDefault();
      try {
        const bearerToken = `Bearer ${token}` ;
        const endpoint = `/ess-jobs/${masqueradeId}/job-status`;
        const data = { essJobDefinition };
        const options = {
          headers: {
            Authorization: bearerToken
          }
        };
        await axios.post(endpoint, data, options);
      } catch (error) {
        console.log('Error getting job status');
        console.log(error);
      }

    // refreshStatus();
  }

  return (
    <main>
      <Paper className={classes.root}>
        <Toolbar className={classNames(classes.toolbar)}>
          <div className={classes.title}>
            <Typography variant="h6" id="tableTitle">Ess Jobs</Typography>
          </div>
        </Toolbar>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Job Name</TableCell>
              <TableCell>Last Run Start</TableCell>
              <TableCell>Last Run End</TableCell>
              <TableCell>Last Run Status</TableCell>
              <TableCell># of Runs</TableCell>
              <TableCell>Avg. Runtime</TableCell>
              <TableCell>Job Type</TableCell>
              <TableCell>Button</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { essJobs.length > 0 ?
              essJobs.map(essJob => <EssJobIndexTableRow key={essJob.definition} essJob={essJob} classes={classes} handleClick={handleClick} />) :
              <TableRow><TableCell>No Data Found</TableCell></TableRow>
            }
          </TableBody>
        </Table>
      </Paper>
    </main>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    masqueradeId: state.masqueradeId
  };
}

export default connect(mapStateToProps)(EssJobIndexTable);
