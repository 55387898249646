import React from 'react';
import { Redirect } from 'react-router-dom';
import TenantShow from '../tenants/TenantShow';
import Routes from '../../util/routes';

const Home = props => {
  const { isAuthenticated } = props;

  return (
    <>
      { isAuthenticated ?
        (<TenantShow />) :
        (<Redirect to={{ pathname: Routes.login }} />)
      }
    </>
  );
};

export default Home;
