import React from 'react';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Clear from '@material-ui/icons/Clear';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Mail from '@material-ui/icons/Mail';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';


export default {
  Edit: React.forwardRef((props, ref) => <Edit {...props} fontSize="small" ref={ref} />),
  Export: React.forwardRef((props, ref) => <SaveAlt {...props} fontSize="small" ref={ref} />),
  Filter: React.forwardRef((props, ref) => <FilterList {...props} fontSize="small" ref={ref} />),
  FirstPage: React.forwardRef((props, ref) => <FirstPage {...props} fontSize="small" ref={ref} />),
  PreviousPage: React.forwardRef((props, ref) => <ChevronLeft {...props} fontSize="small" ref={ref} />),
  NextPage: React.forwardRef((props, ref) => <ChevronRight {...props} fontSize="small" ref={ref} />),
  LastPage: React.forwardRef((props, ref) => <LastPage {...props} fontSize="small" ref={ref} />),
  Mail: React.forwardRef((props, ref) => <Mail {...props} fontSize="small" ref={ref} />),
  SortArrow: React.forwardRef((props, ref) => <ArrowDownward {...props} fontSize="small" ref={ref} />),
  Search: React.forwardRef((props, ref) => <Search {...props} fontSize="small" ref={ref} />),
  ResetSearch: React.forwardRef((props, ref) => <Clear {...props} fontSize="small" ref={ref} />),
  ViewColumn: React.forwardRef((props, ref) => <ViewColumn {...props} fontSize="small" ref={ref} />),
};
