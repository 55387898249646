import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
    root: {
      minWidth: 400,
      maxHeight: 500,
      marginBottom: 20
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 22,
      paddingBottom: 10
    },
    event: {
      width: '100%',
      overflow: 'scroll'
    },
    overflow: {
      maxHeight: 400,
      overflow: 'scroll'
    },
    center: {
      textAlign: 'center'
    }
  });

const EventCard = (props) => {
    const classes = useStyles()
    const {events, tenant, manager} = props
    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography variant="h5" component="h2">
                    {tenant}
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                    {manager}
                </Typography>
                <div className={classes.overflow}>
                  <Table className={classes.event} stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Event Type</TableCell>
                        <TableCell align="center">Event Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {events.map(e => {
                        return (
                        <TableRow>
                          <TableCell align="center">{e.name}</TableCell>
                          <TableCell align="center">{moment(e.date).format('MM/DD/YYYY')}</TableCell>
                        </TableRow>)
                      })}
                    </TableBody>
                  </Table>
                </div>
            </CardContent>
            <CardActions>
                <Button size="small">Action</Button>
            </CardActions>
        </Card>
    )
}

export default EventCard;
