import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MaterialTable, { MTableToolbar } from 'material-table';
import { makeStyles } from '@material-ui/styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import downloadjs from 'downloadjs';
import moment from 'moment';
import DialogWrapper from '../../dialogs/DialogWrapper';
import axios from '../../../services/axios';
import icons from '../../../util/icons';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
}));

const Edi753HeadersReport = props => {
  const classes = useStyles();
  const { token } = props;

  let ediStartDate;
  let ediEndDate;

  if (localStorage.getItem('ediEndDate')) {
    ediEndDate = localStorage.getItem('ediEndDate');
  } else {
    ediEndDate = moment().endOf('day').toISOString();
    localStorage.setItem('ediEndDate', ediEndDate);
  }

  if (localStorage.getItem('ediStartDate')) {
    ediStartDate = localStorage.getItem('ediStartDate');
  } else {
    ediStartDate = moment().subtract(7, 'days').startOf('day').toISOString();
    localStorage.setItem('ediStartDate', ediStartDate);
  }

  const dateToday = new Date(ediEndDate);
  const dateOneWeekAgo = new Date(ediStartDate);

  const [ fromDate, setFromDate ] = useState(dateOneWeekAgo);
  const [ toDate, setToDate ] = useState(dateToday);

  const columns = [
    {
      title: "Raw EDI",
      field: "raw_edi",
      export: false,
      sorting: false,
      filtering: false,
      render: rowData => {
        return (
          <DialogWrapper
            dialogActionButton={
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  return downloadjs(rowData.raw_edi, "RawEdi.edi", "text/plain");
                }}
              >
                Download
              </Button>
            }
            button={({ handleClickOpen }) => {
              return (
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleClickOpen}
                >
                  View
                </Button>
              );
            }}
            dialogTitle='Raw EDI File'
            content={rowData.raw_edi}
          />
        );
      },
    },
    { title: "JT Transaction Id", field: "jt_transaction_id", cellStyle: { whiteSpace: "nowrap", size: "small" } },
    { title: "Customer Name", field: "customer_name", cellStyle: { whiteSpace: "nowrap" } },
    { title: "Customer PO #", field: "customer_po_number", cellStyle: { whiteSpace: "nowrap" } },
    { title: "Do Not Ship Before", field: "do_not_ship_before", cellStyle: { whiteSpace: "nowrap" } },
    { title: "Do Not Ship After", field: "do_not_ship_after", cellStyle: { whiteSpace: "nowrap" } },
    { title: "Edi Location Code", field: "edi_location_code" },
    { title: "Weight (lbs)", field: "weight" },
    { title: `Volume (cubic ft)`, field: "volume" },
    { title: "Reveiver Id", field: "receiver_id", cellStyle: { whiteSpace: "nowrap" } },
    {
      title: "Creation Date",
      field: "creation_date",
      defaultSort: "desc",
      render: rowData => {
        if (rowData.creation_date !== null) {
          return moment(rowData.creation_date).format('MM/DD/YYYY h:mm:ss a');
        }
      }
    },
  ];

  const [headerData, setHeaderData] = useState([]);

  useEffect(() => {
    const fetch753Headers = async () => {
      const endpoint = '/reports/753-transactions';
      const bearerToken = `Bearer ${token}`;
      const options = {
        headers: {
          Authorization: bearerToken
        },
        params: {
          fromDate,
          toDate,
        },
      };

      let transactionData = [];
      try {
        const response = await axios.get(endpoint, options);
        transactionData = response.data.transactions;
        setHeaderData(transactionData);
      } catch (error) {
        transactionData = [];
        setHeaderData(transactionData);
      }
    };

    fetch753Headers();
  }, [token, fromDate, toDate]);

  const renderDateFilter = props => {
    return (
      <>
        <Grid container justify="flex-end">
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="from-date-picker-dialog"
                label="From date"
                format="MM/dd/yyyy"
                value={fromDate}
                onChange={date => {
                  localStorage.setItem('ediStartDate', date.toISOString());
                  setFromDate(date);
                }}
              />
              <KeyboardDatePicker
                margin="normal"
                id="to-date-picker-dialog"
                label="To date"
                format="MM/dd/yyyy"
                value={toDate}
                onChange={date => {
                  localStorage.setItem('ediEndDate', date.toISOString());
                  setToDate(date);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change to date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item>
            <MTableToolbar {...props}/>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <main className={classes.main}>
      <MaterialTable
        columns={columns}
        data={headerData}
        title="EDI 753 Transactions"
        options={{
          filtering: true,
          grouping: false,
          search: false,
          columnsButton: true,
          exportButton: true,
          exportAllData: true,
          padding: 'dense',
          pageSize: 25,
          pageSizeOptions: [25, 50, 100],
          emptyRowsWhenPaging: false,
          showTitle: false,
          maxBodyHeight: "10%",
        }}
        icons={icons}
        components={{
          Toolbar: renderDateFilter
        }}
      />
    </main>
  );
};

const mapStateToProps = (state) => {
  const { token } = state;

  return {
    token
  };
}

export default connect(mapStateToProps)(Edi753HeadersReport);
