import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import axios from '../../services/axios';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: '60%',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(4)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '40%'
  },
  dense: {
    marginTop: 19
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '40%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
});

class TenantForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tenantId: this.props.tenantId ? this.props.tenantId : null,
      tenantName: this.props.tenantName ? this.props.tenantName : '',
      tenantAdminEmail: this.props.tenantAdminEmail ? this.props.tenantAdminEmail : '',
      tenantPlanType: this.props.tenantPlanType ? this.props.tenantPlanType : '',
      tenantMailbox: this.props.tenantMailbox ? this.props.tenantMailbox : '',
      lookups: []
    };
  }

  componentDidMount = () => {
    this.fetchLookups();
  };

  fetchLookups = async () => {
    try {
      const endpoint = `/lookups/plan`;
      const { token } = this.props;
      const bearerToken = `Bearer ${token}`;
      const options = {
        headers: {
          Authorization: bearerToken
        }
      };
      const response = await axios.get(endpoint, options);
      const lookups = response.data.lookups;
      this.setState({lookups});
    } catch {
      console.log('Error fetching lookup list');
    }
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const tenantName = this.state.tenantName;
    const tenantAdminEmail = this.state.tenantAdminEmail;
    const tenantPlanType = this.state.tenantPlanType;
    const tenantMailbox = this.state.tenantMailbox;
    const tenantId = this.state.tenantId;
    const exit = await this.props.onSubmit({ tenantId, tenantName, tenantAdminEmail, tenantPlanType, tenantMailbox });
    if (exit) {
      this.props.handleClose();
    }
  };

  render() {
    return (
      <form className={this.props.classes.form} onSubmit={this.handleSubmit}>
        <TextField
          required
          id="tenant-name-field"
          label="Tenant Name"
          className={this.props.classes.textField}
          margin="normal"
          value={this.state.tenantName}
          onChange={this.handleChange('tenantName')}
        />
        <TextField
          id="tenant-admin-email-field"
          label="Tenant Admin Email"
          className={this.props.classes.textField}
          margin="normal"
          value={this.state.tenantAdminEmail}
          onChange={this.handleChange('tenantAdminEmail')}
        />
        <FormControl className={this.props.classes.formControl}>
          <InputLabel htmlFor="tenant-plan-type-field">Plan</InputLabel>
          <Select
            autoWidth
            id="tenant-plan-type-field"
            value={this.state.tenantPlanType}
            onChange={this.handleChange('tenantPlanType')}
            input={<Input name="age" id="age-auto-width" />}
          >
          {this.state.lookups.map((lookup, idx) => (
            <MenuItem key={idx} value={lookup.id}>
              {lookup.name}
            </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          id="tenant-mailbox-field"
          label="Tenant Mailbox"
          className={this.props.classes.textField}
          margin="normal"
          value={this.state.tenantMailbox}
          onChange={this.handleChange('tenantMailbox')}
        />
        <Button type="submit" variant="contained" color="primary" className={this.props.classes.submit}>
          Submit
        </Button>
      </form>
    );
  }
}

TenantForm.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  const { token } = state;
  return {
    token
  };
}

export default connect(mapStateToProps)(withStyles(styles)(TenantForm));
