import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import _ from 'lodash';
import rootReducer from './reducers';
import { loadState, saveState } from './util/stateHelpers';
import { REDUX_SAVE_STATE_INTERVAL } from './util/constants';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const logger = createLogger();

const persistedState = loadState();

const store = createStore(
    rootReducer,
    persistedState,
    composeEnhancers(applyMiddleware(logger))
);

store.subscribe(_.throttle(() => {
  saveState({
    token: store.getState().token,
    isAuthenticated: store.getState().isAuthenticated,
    masqueradeId: store.getState().masqueradeId,
    userRole: store.getState().userRole,
    snackbarMessage: store.getState().snackbarMessage,
    snackbarState: store.getState().snackbarState,
  })
}, REDUX_SAVE_STATE_INTERVAL));

export default store;
