import React from 'react';
import Fab from '@material-ui/core/Fab';
import MailIcon from '@material-ui/icons/Mail'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import FormDialogWrapper from '../dialogs/FormDialogWrapper';


const EventEmailIndexTableRow = props => {
  const { classes, eventEmail } = props;

  return (
    <TableRow key={eventEmail.id}>
      <TableCell>{eventEmail.email_subject}</TableCell>
      <TableCell>{eventEmail.received_date_time}</TableCell>
      <TableCell>
        <FormDialogWrapper
          button={({ handleClickOpen }) => {
            return (
              <Fab color="primary" className={classes.fab} onClick={handleClickOpen}>
                <MailIcon />
              </Fab>
            )
          }}
          dialogTitle='Event Email'
          content={({handleClickOpen, handleClose}) => {
            return (
              <div
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
              >
                <div dangerouslySetInnerHTML={{ __html: eventEmail.email_content }} />
              </div>
            )
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default EventEmailIndexTableRow;
