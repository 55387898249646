import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import moment from 'moment';
import axios from '../../../services/axios';
import icons from '../../../util/icons';


const Edi810Lines = props => {
  const { invoiceNumber, jtTransactionId, token } = props;
  const [lineData, setLineData] = useState([]);

  const columns = [
    { title: "Item #", field: "item_number" },
    { title: "Quantity Invoiced", field: 'quantity_invoiced' },
    {
      title: "Unit Price",
      field: 'unit_price',
      render: rowData => {
        if (rowData.unit_price) {
          return "$" + rowData.unit_price;
        }

        return null;
      }
    },
    { title: "UOM", field: 'uom' },
    {
      title: "Creation Date",
      field: "creation_date",
      defaultSort: "desc",
      render: rowData => {
        if (rowData.creation_date !== null) {
          return moment(rowData.creation_date).format('MM/DD/YYYY h:mm:ss a');
        }
      }
    },
  ];


  useEffect(() => {
    const fetch810Headers = async () => {
      const endpoint = `/reports/810-lines/transactionId/${jtTransactionId}/invoiceNumber/${invoiceNumber}`;
      const bearerToken = `Bearer ${token}`;
      const options = {
        headers: {
          Authorization: bearerToken
        }
      };

      let lines = [];
      try {
        const response = await axios.get(endpoint, options);
        lines = response.data.lines;
        setLineData(lines);
      } catch (error) {
        lines = [];
        setLineData(lines);
      }
    };

    fetch810Headers();
  }, [invoiceNumber, jtTransactionId, token]);

  return (
    <MaterialTable
      columns={columns}
      data={lineData}
      doubleHorizontalScroll={true}
      icons={icons}
      options={{
        doubleHorizontalScroll: true,
        filtering: true,
        paging: false,
        search: false,
        showTitle: false,
        sorting: false,
        title: false,
        toolbar: false,
      }}
    />
  );
};

const mapStateToProps = (state) => {
  const { token } = state;

  return {
    token
  };
}

export default connect(mapStateToProps)(Edi810Lines);
