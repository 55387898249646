import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import FormDialogWrapper from '../dialogs/FormDialogWrapper';
import ActivityIndexTableRow from './ActivityIndexTableRow';
import ActivityForm from '../forms/ActivityForm';
import myAxios from '../../services/axios';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(3),
    }
  },
  root: {
    width: 'auto',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: '100%',
  },
  toolbar: {
    paddingRight: theme.spacing(1)
  },
  spacer: {
    flex: '1 1 100%'
  },
  fab: {
    margin: theme.spacing(2)
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  }
}));

function ActivityIndexTable(props) {
  const classes = useStyles();
  const { token, setRefreshFlag, refreshFlag, eventId } = props;

  const [activities, setActivities] = useState([]);

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const fetchActivities = () => {
      const endpoint = `/activities/byEventId/${eventId}`;
      const bearerToken = `Bearer ${token}`;
      const options = {
        headers: {
          Authorization: bearerToken
        },
        cancelToken: source.token,
      };

      myAxios
        .get(endpoint, options)
        .then(({ data }) => {
          if (data) {
            const { activities } = data;
            setActivities(activities);
          }
        })
        .catch(err => console.log(`Error retriving activities for event ${eventId}`));
    };

    fetchActivities();

    return () => source.cancel();
  }, [token, eventId, refreshFlag]);

  const handleEditActivity = activity => {
    return;
  }

  const renderActivityRows = activities => {
    if (!activities || activities.length <= 0) {
      return (
        <TableRow key={-1}><TableCell>No Data Found</TableCell></TableRow>
      );
    }
    return activities.map(activity => {
      return (
        <ActivityIndexTableRow
          key={activity.id}
          activity={activity}
          handleEditActivity={handleEditActivity}
        />
      );
    });
  };

  const handleCreateActivity = async payload => {
    try {
      const bearerToken = `Bearer ${token}`;
      const endpoint = `/activities`;
      const config = {
        headers: {
          Authorization: bearerToken
        }
      };

      const response = await myAxios.post(endpoint, payload, config);

      if (response.status === 201) {
        setRefreshFlag(!refreshFlag);
        return true;
      }
    } catch (err) {
      console.log('Error in create activity');
      console.log(err);
      return false;
    }

    return false;
  };

  const renderToolbar = () => {
    return (
      <Toolbar className={classNames(classes.toolbar)}>
        <div className={classes.title}>
          <Typography variant="h6" id="tableTitle">Activities</Typography>
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <FormDialogWrapper
            button={({ handleClickOpen }) => {
              return(
                <Tooltip title="Add" aria-label="Add">
                  <Fab color="primary" className={classes.fab} onClick={handleClickOpen}>
                    <AddIcon />
                  </Fab>
                </Tooltip>
              );
            }}
            buttonText="Add Activity"
            dialogTitle='Adding Activity'
            content={({ handleClickOpen, handleClose }) => {
              return (
                <ActivityForm
                  eventId={eventId}
                  onSubmit={ handleCreateActivity }
                  handleClickOpen={handleClickOpen}
                  handleClose={handleClose}
                />
              );
            }}
          />
        </div>
      </Toolbar>
    );
  }

  return (
    <main className={classes.main}>
      <Paper className={classes.root}>
        {renderToolbar()}
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Activity Type</TableCell>
              <TableCell>Activity Date</TableCell>
              <TableCell>Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { renderActivityRows(activities) }
          </TableBody>
        </Table>
      </Paper>
    </main>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token
  };
}

export default connect(mapStateToProps)(ActivityIndexTable);
