import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import moment from 'moment';
import axios from '../../../services/axios';
import icons from '../../../util/icons';


const Edi850Lines = props => {
  const { jtTransactionId, token } = props;
  const [lineData, setLineData] = useState([]);

  const columns = [
    { title: "Vendor Item #", field: "vendor_item_number" },
    { title: "Customer Item #", field: "customer_item_number" },
    { title: "UPC", field: 'upc' },
    { title: "GTIN", field: 'gtin' },
    { title: "EDI Price", field: 'price', type: 'currency' },
    {
      title: "Oracle Price",
      field: 'oracle_price',
      render: rowData => {
        if (rowData.oracle_price) {
          return `$${parseFloat(rowData.oracle_price).toFixed(2)}`;
        }

        return null;
      }
    },
    { title: "UOM", field: 'uom' },
    { title: "Quantity", field: 'quantity' },
    { title: "Process Status", field: "process_status" },
    { title: "Process Message", field: "process_message" },
    {
      title: "Creation Date",
      field: "creation_date",
      defaultSort: "desc",
      render: rowData => {
        if (rowData.creation_date !== null) {
          return moment(rowData.creation_date).format('MM/DD/YYYY h:mm:ss a');
        }
      }
    },
  ];


  useEffect(() => {
    const fetch850Headers = async () => {
      const endpoint = `/reports/850-lines/${jtTransactionId}`;
      const bearerToken = `Bearer ${token}`;
      const options = {
        headers: {
          Authorization: bearerToken
        }
      };

      let lines = [];
      try {
        const response = await axios.get(endpoint, options);
        lines = response.data.lines;
        setLineData(lines);
      } catch (error) {
        lines = [];
        setLineData(lines);
      }
    };

    fetch850Headers();
  }, [jtTransactionId, token]);

  return (
    <MaterialTable
      columns={columns}
      data={lineData}
      doubleHorizontalScroll={true}
      icons={icons}
      options={{
        doubleHorizontalScroll: true,
        filtering: true,
        paging: false,
        search: false,
        showTitle: false,
        sorting: false,
        title: false,
        toolbar: false,
      }}
    />
  );
};

const mapStateToProps = (state) => {
  const { token } = state;

  return {
    token
  };
}

export default connect(mapStateToProps)(Edi850Lines);
